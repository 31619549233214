import { Checkbox, Col, Form, Radio, RadioChangeEvent, Row, Select } from "antd";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { taxYears } from "../../utils/helpers";
import { RootState } from "../../configureStore";
import { IBorrower } from "../../utils/interfaces/borrower";

interface FormTypesParams {
    setHasBusinessForm?: (_: boolean) => void;
    style?: CSSProperties;
    index?: number;
    type?: 'Individual' | 'Business';
    existing?: boolean;
}

const indForms = [
    '1040 - Return Transcript',
    '1040 - Record of Account',
    'W-2 - Employee Earnings',
    '1099 - All Types',
];

const bussForms = [
    '1065 - Partnership Return Transcript',
    '1065 - Partnership Record of Account',
    '1120 - Corporate Return Transcript',
    '1120 - Corporate Record of Account',
    '1120S - S Corporation Return Transcript',
];

const formTypes = [
    ...indForms,
    ...bussForms,
    'All Income Data',
];

const FormTypes = ({
    setHasBusinessForm,
    style = {},
    index,
    type,
    existing = false,
}: FormTypesParams) => {
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const vTin = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => borrowers[activeBorrower || '']?.verifyTIN || false);
    const verifiable = useSelector<RootState, boolean>(({ encompass }) => encompass.origin?.verifiableTIN || false);
    const [isTinVerification, setIsTinVerification] = useState<boolean>(false);
    const [isMultiYear, setIsMultiYear] = useState<boolean>(true);
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { activeBorrower, borrowers } }) => (activeBorrower ? borrowers[activeBorrower] : undefined));

    useEffect(() => {
        setIsMultiYear(true);
    }, [borrower]);

    const formOptions = useMemo<string[]>(() => {
        if (!type) return formTypes;
        if (type === 'Business') return bussForms;
        return [...indForms, 'All Income Data'];
    }, [type]);

    return (
        <div style={{ ...style, height: '93%' }} className="form-section">
            <h5 style={{ marginBottom: 4 }}>Form Types & Available Years *</h5>
            <Form.Item
                getValueFromEvent={(ev: any) => {
                    setIsTinVerification(ev.target.checked);
                    return ev.target.checked;
                }}
                name={index != null ? [index, "verifyTIN"] : "verifyTIN"}
                valuePropName="checked"
                className="mb-1"
                style={{ display: (vTin || !verifiable) ? 'none' : undefined }}
            >
                <Checkbox>Pre-Authorization - No Transcripts</Checkbox>
            </Form.Item>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name={index != null ? [index, "formList"] : "formList"}
                        required={!isTinVerification}
                        rules={[{ required: isTinVerification ? false : isEdit ? false : !(isAdd && existing), message: 'Form Type is required.' }]}
                        getValueFromEvent={(forms: string[]) => {
                            setHasBusinessForm && setHasBusinessForm(forms.some((v) => bussForms.includes(v)));
                            return forms;
                        }}
                    >
                        <Checkbox.Group style={{ flexDirection: 'column' }}>
                            {formOptions.map((ft) => (
                                <Checkbox
                                    key={`${type}-ft-${ft}`}
                                    value={ft}
                                    disabled={isTinVerification}
                                >
                                    {ft}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Radio.Group
                        value={isMultiYear}
                        onChange={(e: RadioChangeEvent) => { setIsMultiYear(e.target.value); }}
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <div className="flex-center-center">
                            <Radio
                                value={Boolean(true)}
                                className="mr-2"
                                disabled={isTinVerification}
                            />
                            <Form.Item
                                name={index != null ? [index, "multi"] : "multi"}
                                initialValue="Last 2 Years"
                                noStyle
                            >
                                <Select
                                    options={[{ value: 'Last 2 Years' }, { value: 'Last 3 Years' }]}
                                    defaultValue="Last 2 Years"
                                    style={{ width: 160 }}
                                    disabled={!isMultiYear || isTinVerification}
                                />
                            </Form.Item>
                        </div>
                        <Radio
                            value={false}
                            className="mr-2 mt-1"
                            disabled={isTinVerification}
                            rootClassName="radio-top"
                        >
                            <Form.Item
                                name={index != null ? [index, "yearList"] : "yearList"}
                                required={!isTinVerification}
                                rules={[{ required: !isTinVerification, message: 'Tax Year is required.' }]}
                            >
                                <Checkbox.Group
                                    style={{
                                        flexDirection: 'column',
                                        marginLeft: 16,
                                    }}
                                >
                                    {taxYears().map((ty) => (
                                        <Checkbox
                                            key={`${type}-ty-${ty}`}
                                            disabled={isMultiYear || isTinVerification}
                                            value={ty}
                                        >
                                            {ty}
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </Radio>
                    </Radio.Group>
                </Col>
            </Row>
        </div>
    );
};

export default FormTypes;

import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, ProxyFile, UploadReturnOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class UploadReturnTransaction extends Transaction<UploadReturnOptions> {
    constructor(
        primaryBorrowerId: string,
        files: ProxyFile[],
        origin: APIOrigin,
    ) {
        super(
            primaryBorrowerId,
            {
                loanNumber: origin.origin.loan.loanNumber,
                loanProfessional: origin.origin.originatingParty.pointOfContact,
                type: "IRS Wage and Income",
                requestType: "Return Upload",
                primaryBorrowerId,
                instanceId: origin.instanceId,
            },
            files,
        );
    }

    public toTransaction(): TransactionRequest<UploadReturnOptions> {
        return super.toTransaction();
    }
}

import {
    Button,
    Col,
    Divider,
    Modal,
    Row,
    Typography,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../atoms/Icon';
import { AppDispatch, RootState } from '../../configureStore';
// import { Me } from '../../redux/reducer/user';
import { saveCalculation } from '../../redux/reducer/analyzer';
import { Calculation, CurrentAudit, FormHash } from '../../utils/interfaces/halcyon360';
import { PointOfContact, TransactionOrigin } from '../../utils/interfaces/encompass';

interface IncomeCalculationHeaderParams {
    isLocked: boolean;
    createdBy?: string;
    loanNumber: string;
}

const IncomeCalculationHeader = ({
    isLocked,
    createdBy,
    loanNumber,
}: IncomeCalculationHeaderParams) => {
    const [lockOpen, setLockOpen] = useState<boolean>(false);
    const [colorsOpen, setColorsOpen] = useState<boolean>(false);

    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const me = useSelector<RootState, PointOfContact | undefined>(({ encompass: { origin } }) => origin?.origin?.originatingParty?.pointOfContact);

    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['analyzer/SAVE_CALCULATORS']);
    const calcCur = useSelector<RootState, Calculation>(({ analyzer: { calculation } }) => calculation);
    const formHashes = useSelector<RootState, FormHash>(({ analyzer }) => analyzer.formHashes);
    const updates = useSelector<RootState, CurrentAudit[]>(({ analyzer: { currentUpdates } }) => Object.values(currentUpdates).flatMap((cu) => Object.values(cu)));

    const dispatch = useDispatch<AppDispatch>();
    return (
        <>
            <Modal
                open={lockOpen}
                footer={null}
                closable={false}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                styles={{ content: { width: '325px' } }}
                onCancel={() => { setLockOpen(false); }}
            >
                <div style={{ alignContent: 'center', textAlign: 'center' }}>
                    <Icon
                        name={isLocked ? 'UnlockOutlined' : 'LockOutlined'}
                        size="40px"
                        color="#FAAD14"
                        backgroundColor="#FFFBE6"
                        padding={16}
                        className="mb-2"
                    />
                    <Typography.Title level={4}>
                        {isLocked ? createdBy === me?.email ? 'Are you sure you want to unlock this file?' : `This file was locked by ${createdBy}. Please ask them to reopen it.` : 'Are you sure you want to lock this file?'}
                    </Typography.Title>
                </div>
                <Divider className="my-2" />
                <Row gutter={8} align="middle" justify="space-around">
                    <Col xs={12}><Button className="w100" size="large" onClick={() => { setLockOpen(false); }}>No</Button></Col>
                    <Col xs={12}>
                        <Button
                            disabled={isLocked && createdBy !== me?.email}
                            className="w100"
                            size="large"
                            loading={loading}
                            onClick={() => {
                                if (txnOrgn && me) {
                                    if (isLocked) {
                                        dispatch(saveCalculation({
                                            input: {
                                                OriginId: txnOrgn?.id,
                                                PartnerAccessToken: txnOrgn?.partnerAccessToken,
                                                createdBy: me?.email,
                                                calculator: {
                                                    calculator: calcCur,
                                                    loanNumber,
                                                    lockFile: false,
                                                    unlockFile: true,
                                                    oldId: calcCur.calculatorId,
                                                    updates: [
                                                        {
                                                            date: new Date(),
                                                            fieldId: 'root',
                                                            iii: 'root',
                                                            type: 'lock',
                                                            value: false,
                                                        },
                                                    ],
                                                    formHashes,
                                                    name: calcCur.name,
                                                },
                                            },
                                            onSuccess: () => { setLockOpen(false); },
                                        }));
                                    } else {
                                        dispatch(saveCalculation({
                                            input: {
                                                OriginId: txnOrgn?.id,
                                                PartnerAccessToken: txnOrgn?.partnerAccessToken,
                                                createdBy: me.email,
                                                calculator: {
                                                    calculator: calcCur,
                                                    loanNumber,
                                                    lockFile: true,
                                                    unlockFile: false,
                                                    oldId: calcCur.calculatorId,
                                                    updates: [
                                                        ...updates,
                                                        {
                                                            date: new Date(),
                                                            fieldId: 'root',
                                                            iii: 'root',
                                                            type: 'lock',
                                                            value: true,
                                                        },
                                                    ],
                                                    formHashes,
                                                    name: calcCur.name,
                                                },
                                            },
                                            onSuccess: () => { setLockOpen(false); },
                                        }));
                                    }
                                }
                            }}
                            type="primary"
                        >
                            Yes
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                open={colorsOpen}
                footer={null}
                onCancel={() => { setColorsOpen(false); }}
                title="Colors Info"
            >
                <ul className="colors-list">
                    <li><div className="custom-bullet yellow">Sections highlighted in yellow need to be filled in manually, as they are not available on IRS Transcripts</div></li>
                    {/* <li><div className="custom-bullet purple">Sections highlighted in purple are filled from Tax returns</div></li> */}
                    <li><div className="custom-bullet edited-bullet">Red indicator on a field shows that the field has been manually edited</div></li>
                </ul>
            </Modal>
            <Row justify="space-between">
                <Row justify="start" align="middle">
                    <Typography.Title level={4} className="mb-0 mr-2">Income Analyzer</Typography.Title>
                    <Icon
                        name={isLocked ? 'LockOutlined' : 'UnlockOutlined'}
                        size="large"
                        onClick={() => { setLockOpen(!lockOpen); }}
                    />
                    {isLocked && (
                        <Typography
                            style={{
                                fontSize: '13px',
                                textAlign: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            This file is locked
                        </Typography>
                    )}
                </Row>
                <Row justify="end" align="middle">
                    <Button
                        icon={(<Icon name="ExclamationCircleOutlined" />)}
                        onClick={() => { setColorsOpen(!colorsOpen); }}
                    >
                        Colors Info
                    </Button>
                </Row>
            </Row>
        </>
    );
};

export default IncomeCalculationHeader;

import { useEffect } from 'react';
import { Modal, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../configureStore';
import {
    Calculation,
    YearFlags,
} from '../../utils/interfaces/halcyon360';
import { getViewCalculator, RESET_PREVIEW } from '../../redux/reducer/analyzer';
import { TaxPayer, TaxPayerYear } from '../../utils/interfaces/analyzer';
import Summary from './Analyzer/Summary';
import { TransactionOrigin } from '../../utils/interfaces/encompass';

interface PreviewAnalyzerModalParams {
    id: string | null;
    onClose: () => void;
}

const PreviewAnalyzerModal = ({
    id,
    onClose,
}: PreviewAnalyzerModalParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);

    const loanNumber = useSelector<RootState, string>(({ analyzer: { loanNumber: ln } }) => ln || '');
    const calculation = useSelector<RootState, Calculation | undefined>(({ analyzer: { preview } }) => preview);
    const taxPayers = useSelector<RootState, { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; flags: YearFlags; }; }>(({ analyzer: { taxPayers: tp } }) => tp);

    useEffect(() => {
        if (id && txnOrgn) {
            dispatch(getViewCalculator({
                input: {
                    loanNumber,
                    calculatorId: id,
                    originId: txnOrgn.id,
                    partnerAccessToken: txnOrgn.partnerAccessToken,
                },
            }));
        } else dispatch({ type: RESET_PREVIEW });
    }, [id]);

    return (
        <Modal
            title="Summary"
            open={Boolean(id)}
            width="90%"
            onCancel={onClose}
            onOk={onClose}
            cancelText="Close"
        >
            {
                calculation ? (
                    <>
                        <Typography className="my-3">
                            Created By:
                            {' '}
                            {calculation?.createdBy}
                        </Typography>
                        {Object.keys(calculation?.data || {}).filter((k) => k !== 'any').map((iii) => (calculation ? (
                            <Summary
                                summary={calculation.data[iii].summary}
                                usedYears={calculation.data[iii].usedYears || []}
                                name={taxPayers[iii].info.firstName === 'all' ? 'All' : taxPayers[iii].info.firstName ? `${taxPayers[iii].info.firstName} ${taxPayers[iii].info.lastName}` : taxPayers[iii].info.name}
                            />
                        ) : <div />))}
                    </>
                ) : <Spin />
            }
        </Modal>
    );
};

export default PreviewAnalyzerModal;

import { useState } from 'react';
import { DateTime } from 'luxon';
import {
    Card,
    Row,
    Table,
    Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../atoms/Icon';
import { AppDispatch, RootState } from '../../configureStore';
import { CalculatorHistory } from '../../utils/interfaces/halcyon360';
import { TOGGLE_ANALYZER_HISTORY } from '../../redux/reducer/analyzer';
import ReviewChangesModal from '../organisms/ReviewChangesModal';
import PreviewAnalyzerModal from '../organisms/PreviewAnalyzerModal';
import MoreMenu from '../molecules/MoreMenu';

interface AnalyzerHistoryParams {
}

const AnalyzerHistory = ({
}: AnalyzerHistoryParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const [reviewModal, setReviewModal] = useState<string | null>(null);
    const [previewModal, setPreviewModal] = useState<string | null>(null);

    const loading = useSelector<RootState, boolean>(({ status: { loading: l } }) => l['analyzer/GET_VIEW_CALCULATOR']);
    const versions = useSelector<RootState, CalculatorHistory[]>(({ analyzer: { calculation: c } }) => (c?.history || []).filter(({ version }) => version !== -1));

    return (
        <Card
            title={(
                <Row justify="start" align="middle">
                    <Icon name="ArrowLeftOutlined" onClick={() => { dispatch({ type: TOGGLE_ANALYZER_HISTORY }); }} />
                    <Typography.Title className="ml-2 mb-0" level={5}>Back</Typography.Title>
                </Row>
            )}
        >
            <ReviewChangesModal id={reviewModal} onClose={() => setReviewModal(null)} />
            <PreviewAnalyzerModal id={previewModal} onClose={() => setPreviewModal(null)} />
            <Table
                columns={[
                    {
                        dataIndex: 'version',
                        title: 'Version',
                        render: (v: number) => (v || 'Original'),
                        sorter: (a, b) => a.version - b.version,
                    },
                    {
                        dataIndex: 'createdTime',
                        title: 'Created Time',
                        render: (ct: string) => DateTime.fromISO(ct).toFormat('LLL dd, yyyy - h:mm a'),
                        sorter: (a, b) => DateTime.fromISO(a.createdTime).toUnixInteger() - DateTime.fromISO(b.createdTime).toUnixInteger(),
                    },
                    {
                        dataIndex: 'createdBy',
                        title: 'Created By',
                        onFilter: (a, b) => b.createdBy === a,
                        filters: versions.map(({ createdBy }) => ({ text: createdBy, value: createdBy })).filter((value, index, self) => index === self.findIndex((t) => t.text === value.text && t.value === value.value)),
                    },
                    {
                        dataIndex: 'notes',
                        title: 'Notes',
                    },
                    {
                        dataIndex: 'calculatorId',
                        title: 'Action',
                        align: 'center',
                        width: '48px',
                        render: (calculatorId) => (
                            <MoreMenu options={[
                                {
                                    name: 'Review Changes',
                                    onClick: () => { setReviewModal(calculatorId); },
                                },
                                {
                                    name: 'Preview',
                                    onClick: () => { setPreviewModal(calculatorId); },
                                },
                                // {
                                //     name: 'Revert Version',
                                //     onClick: () => { },
                                // },
                            ]}
                            />
                        ),
                    },
                ]}
                dataSource={versions}
                rowKey="calculatorId"
                loading={loading}
            />
        </Card>
    );
};

export default AnalyzerHistory;

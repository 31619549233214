import { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Col, Input, Radio, RadioChangeEvent, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TextArea from 'antd/es/input/TextArea';
import { AppDispatch, RootState } from '../../configureStore';
import { saveCalculation, SET_CALCULATOR_ID } from '../../redux/reducer/analyzer';
import { Calculation, CurrentAudit, FormHash } from '../../utils/interfaces/halcyon360';
import { PointOfContact, TransactionOrigin } from '../../utils/interfaces/encompass';

interface CalculatorSelectorParams {
    loanNumber: string;
}

const CalculatorSelector = ({
    loanNumber,
}: CalculatorSelectorParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const me = useSelector<RootState, PointOfContact | undefined>(({ encompass: { origin } }) => origin?.origin?.originatingParty?.pointOfContact);

    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['analyzer/SAVE_CALCULATORS']);
    const calcs = useSelector<RootState, { [key: string]: string; }>(({ analyzer: { types } }) => types);
    const calculatorId = useSelector<RootState, string | undefined>(({ analyzer: { calculation } }) => calculation.id);
    const calcCur = useSelector<RootState, Calculation>(({ analyzer: { calculation } }) => calculation);
    const current = useSelector<RootState, CurrentAudit[]>(({ analyzer: { currentUpdates } }) => Object.values(currentUpdates).flatMap((cu) => Object.values(cu)));
    const formHashes = useSelector<RootState, FormHash>(({ analyzer }) => analyzer.formHashes);
    const hasChanges = useSelector<RootState, boolean>(({ analyzer: { currentUpdates } }) => Object.keys(currentUpdates || {}).length > 0);
    const [tempId, setTempId] = useState<string | null>();
    const [notes, setNotes] = useState<string>();
    const [name, setName] = useState<string>(calcCur.name);
    const [open, setOpen] = useState<boolean>(false);
    const [isSave, setIsSave] = useState<boolean>(false);

    return (
        <>
            <Modal
                open={open}
                cancelText="Don't Save"
                cancelButtonProps={{
                    onClick: () => {
                        dispatch({ type: SET_CALCULATOR_ID, id: tempId });
                        setTempId(null);
                        setOpen(false);
                    },
                    loading,
                }}
                okText={isSave ? 'Confirm' : 'Save'}
                okButtonProps={{
                    loading,
                    onClick: () => {
                        if (!isSave) setIsSave(true);
                        else {
                            let updates: CurrentAudit[] = current;
                            if (name !== calcCur.name) {
                                updates = [
                                    ...current,
                                    {
                                        date: new Date(),
                                        iii: 'root',
                                        type: 'filename',
                                        fieldId: 'root',
                                        value: name,
                                    },
                                ];
                            }
                            dispatch(saveCalculation({
                                input: {
                                    OriginId: txnOrgn?.id || '',
                                    PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                                    createdBy: me?.email || '',
                                    calculator: {
                                        calculator: calcCur,
                                        loanNumber,
                                        lockFile: false,
                                        oldId: calcCur.calculatorId,
                                        name,
                                        notes,
                                        updates,
                                        formHashes,
                                    },
                                },
                                onSuccess: () => {
                                    setTempId(null);
                                    dispatch({ type: SET_CALCULATOR_ID, id: tempId });
                                    setOpen(false);
                                },
                            }));
                        }
                    },
                }}
                onCancel={() => {
                    setTempId(null);
                    setOpen(false);
                }}
            >
                {
                    isSave
                        ? (
                            <div className="mt-6 mb-2">
                                <Row gutter={8} className="mt-6 mb-2">
                                    <Col span={24}>
                                        <Input
                                            value={name}
                                            placeholder="Name"
                                            required
                                            onChange={(e) => { setName(e.target.value); }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <TextArea
                                            placeholder="Notes"
                                            value={notes}
                                            onChange={(e) => { setNotes(e.target.value); }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                        : 'You may have unsaved changes'
                }
            </Modal>
            <Radio.Group
                value={calculatorId}
                onChange={(e: RadioChangeEvent) => {
                    if (hasChanges) {
                        setTempId(e.target.value);
                        setOpen(true);
                    } else {
                        dispatch({ type: SET_CALCULATOR_ID, id: e.target.value });
                    }
                }}
            >
                {Object.keys(calcs).map((calc) => (
                    <Radio
                        key={`h-360-calc-type-${calc}`}
                        value={calcs[calc]}
                        style={{ fontWeight: 'bold' }}
                    >
                        {calc}
                    </Radio>
                ))}
            </Radio.Group>
        </>
    );
};

export default CalculatorSelector;

import {
    Button,
    Col,
    Modal,
    Row,
    Tabs,
    Typography,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EncompassFileSelector from '../atoms/EncompassFileSelector';
import { APIOrigin, IEncompassResource, ProxyFile } from '../../utils/interfaces/encompass';
import { AppDispatch, RootState } from '../../configureStore';
import { sendEncompassRequest } from '../../redux/reducer/encompass';
import { UploadReturnTransaction } from '../../utils/encompass/uploadReturn';
import Icon from '../atoms/Icon';

interface UploadReturnModalParams {
    open: boolean;
    setOpen: (_: boolean) => void;
}

const UploadReturnModal = ({
    open,
    setOpen,
}: UploadReturnModalParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/CREATE_TRANSACTION'] || status.success['encompass/CREATE_TRANSACTION']);
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass.origin);
    const fileNames = useSelector<RootState, string[]>(({ analyzer: { taxReturns } }) => taxReturns.fileNames ?? []);

    const [fileList, setFileList] = useState<IEncompassResource[]>([]);

    return (
        <Modal
            open={open}
            destroyOnClose
            footer={null}
            onCancel={() => {
                setFileList([]);
                setOpen(false);
            }}
        >
            <Typography.Title
                level={5}
                className="mb-4-i"
            >
                Upload Tax Return Data
            </Typography.Title>
            <Tabs
                centered
                items={[
                    {
                        key: 'new',
                        label: 'New',
                        children: (
                            <>
                                <EncompassFileSelector
                                    value={fileList}
                                    onChange={(e, r) => {
                                        if (r) setFileList((e ?? []));
                                        else setFileList([...fileList, ...(e ?? [])]);
                                    }}
                                    allow={64}
                                    center
                                />
                                <Row justify="space-between" align="middle">
                                    <Col><Button onClick={() => { setFileList([]); setOpen(false); }}>Close</Button></Col>
                                    <Col>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            onClick={() => {
                                                if (origin) {
                                                    const input: UploadReturnTransaction = new UploadReturnTransaction(origin.origin.loan.applications[0].borrower.id, fileList.map<ProxyFile>((f) => ({ ...f, docType: 0 })), origin);
                                                    dispatch(sendEncompassRequest({
                                                        input,
                                                        onSuccess: () => {
                                                            localStorage.setItem("LAST_REFRESH", "UPLOAD_RETURN");
                                                            setTimeout(() => { window.location.reload(); }, 2500);
                                                        },
                                                    }));
                                                }
                                            }}
                                        >
                                            {loading ? 'Uploading' : 'Upload'}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        key: 'all',
                        label: 'All Files',
                        disabled: fileNames.length === 0,
                        children: (
                            <>
                                {fileNames.map((fn, idx) => (
                                    <Row
                                        key={`file-${fn}-${idx}`}
                                        justify="start"
                                        align="middle"
                                        gutter={8}
                                        className="my-2"
                                    >
                                        <Col><Icon bordered name="FileOutlined" /></Col>
                                        <Col><Typography>{fn}</Typography></Col>
                                    </Row>
                                ))}
                                <Row
                                    justify="start"
                                    align="middle"
                                    gutter={8}
                                    className="mt-4"
                                >
                                    <Button onClick={() => { setFileList([]); setOpen(false); }}>Close</Button>
                                </Row>
                            </>
                        ),
                    },
                ]}
                tabBarStyle={{ width: '100%' }}
            />
        </Modal>
    );
};

export default UploadReturnModal;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Button, Card, notification, Spin, Tooltip } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { AppDispatch, RootState } from "../../configureStore";
import { getCalculatorData, RESET_ANALYZER_STATE, saveCalculation, SET_CALCULATOR_BORROWER, SET_CALCULATOR_ID } from "../../redux/reducer/analyzer";
import { TaxPayer, TaxPayerYear } from "../../utils/interfaces/analyzer";
import { Calculation, YearFlags } from "../../utils/interfaces/halcyon360";
import { PointOfContact, TransactionOrigin } from "../../utils/interfaces/encompass";
import Header from "../organisms/Analyzer/Header";
import FormsUsed from "../organisms/FormsUsed";
import Calculator from "../organisms/Analyzer/Calculator";
import { mergeUpdatedCalculator, populateCalculator } from "../../utils/halcyon360.ts";
import AnalyzerHistory from "./AnalyzerHistory";

interface AnalyzerParams {
}

const Analyzer = ({
}: AnalyzerParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const me = useSelector<RootState, PointOfContact | undefined>(({ encompass: { origin } }) => origin?.origin?.originatingParty?.pointOfContact);

    const isLocked = useSelector<RootState, boolean>(({ analyzer: { calculation } }) => calculation.isLocked || false);
    const loanNumber = useSelector<RootState, string>(({ encompass: { origin } }) => origin?.origin.loan.loanNumber || '');
    const firstLoading = useSelector<RootState, boolean>(({ status: { loading: l, success: s } }) => ((l['analyzer/GET_ANALYZER'] && !s['analyzer/GET_ANALYZER']) || l['analyzer/GET_ANALYZER'] === undefined));
    const doneFirst = useSelector<RootState, boolean>(({ analyzer: { doneFirst: df } }) => df);
    const noData = useSelector<RootState, boolean>(({ analyzer: { noData: nd } }) => nd);
    const allBorrowers = useSelector<RootState, { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; }; }>(({ analyzer: { taxPayers } }) => taxPayers);
    const calculators = useSelector<RootState, { [calculatorId: string]: Calculation }>(({ analyzer: { calculators: clcs } }) => clcs);
    const devInfo = useSelector<RootState, { loanNumber: string; institutionName: string; } | undefined>(({ analyzer: { devInfo: di } }) => di);
    const currentcalculation = useSelector<RootState, Calculation | undefined>(({ analyzer: { calculation } }) => calculation);
    const taxPayers = useSelector<RootState, { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; flags: YearFlags; }; }>(({ analyzer: { taxPayers: tp } }) => tp);
    const calculatorType = useSelector<RootState, string | undefined>(({ analyzer: { calculation } }) => calculation.type);
    const historyOpen = useSelector<RootState, boolean>(({ analyzer: { history } }) => history.open && !history.divisionId);
    const changeBased = useSelector<RootState, { [calculatorId: string]: Calculation }>(({ analyzer: { changeBased: clcs } }) => clcs);

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

    useEffect(() => () => {
        dispatch({ type: RESET_ANALYZER_STATE });
    }, []);

    const [calculator, setCalculator] = useState<string>('');

    const setSelectedBorrower = (str: string) => {
        dispatch({ type: SET_CALCULATOR_BORROWER, id: str });
    };

    useEffect(() => {
        if (txnOrgn) {
            dispatch(getCalculatorData({ input: { originId: txnOrgn?.id, partnerAccessToken: txnOrgn?.partnerAccessToken } }));
        }
    }, [txnOrgn]);

    useEffect(() => {
        if (!calculator && Object.values(calculators).length) {
            setCalculator(Object.values(calculators)[0].calculatorId);
        }
    }, [calculators]);

    return (
        <div className="w80" style={{ margin: 'auto' }}>
            {
                (!allBorrowers) ? <LoadingOutlined /> : (!historyOpen) ? (
                    <div>
                        {(['dev', 'development', 'qa'].includes(process.env.NODE_ENV || '') && devInfo) && (
                            <div className="flex-end-center mb-1">
                                <Tooltip
                                    placement="left"
                                    title={(
                                        <p className="mb-0">
                                            {devInfo.institutionName}
                                            {': '}
                                            {devInfo.loanNumber}
                                        </p>
                                    )}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>
                        )}
                        <Card
                            style={{ display: 'block' }}
                            className="mb-4"
                            title={(
                                <Header
                                    loanNumber={loanNumber || ''}
                                    setCalculator={(e) => {
                                        dispatch({ type: SET_CALCULATOR_ID, id: e });
                                        setCalculator(e);
                                    }}
                                    noData={noData}
                                />
                            )}
                        >
                            {(changeBased?.[calculatorType || ''] && currentcalculation) && (
                                <Alert
                                    className="mb-2 flex-center-between"
                                    style={{
                                        textAlign: 'start',
                                    }}
                                    description="A new version of this Analyzer is available. Would you like to update?"
                                    type="warning"
                                    action={(
                                        <Button
                                            size="small"
                                            type="primary"
                                            disabled={loadingUpdate}
                                            onClick={() => {
                                                if (isLocked) {
                                                    notification.error({ message: 'Please unlock this File first' });
                                                } else {
                                                    setLoadingUpdate(true);
                                                    const newCalc = populateCalculator(structuredClone(changeBased[calculatorType || '']), taxPayers);
                                                    mergeUpdatedCalculator(newCalc, currentcalculation);
                                                    if (loanNumber) {
                                                        dispatch(saveCalculation({
                                                            input: {
                                                                OriginId: txnOrgn?.id || '',
                                                                PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                                                                createdBy: me?.email || '',
                                                                calculator: {
                                                                    calculator: newCalc,
                                                                    loanNumber,
                                                                    lockFile: false,
                                                                    name: currentcalculation.name,
                                                                    oldId: currentcalculation.calculatorId,
                                                                    formHashes: currentcalculation.formHashes || {},
                                                                    updates: [{
                                                                        date: new Date(),
                                                                        fieldId: 'root',
                                                                        iii: 'root',
                                                                        type: 'base-version',
                                                                        value: newCalc.baseVersion,
                                                                    }],
                                                                },
                                                            },
                                                            onSuccess: () => {
                                                                setLoadingUpdate(false);
                                                                window.location.reload();
                                                            },
                                                        }));
                                                    }
                                                }
                                            }}
                                        >
                                            Accept
                                        </Button>
                                    )}
                                    closable
                                />

                            )}
                            <FormsUsed />
                        </Card>
                        {(firstLoading && !doneFirst) ? (
                            <Spin
                                className="flex-center-center h70"
                                size="large"
                            />
                        ) : noData ? (
                            <div className="flex-center-center col h70">
                                <h2>
                                    We do not have any data available for this Loan!
                                </h2>
                            </div>
                        ) : (
                            <Calculator
                                setSelectedBorrower={setSelectedBorrower}
                                changeCalculator={setCalculator}
                            />
                        )}
                    </div>
                ) : (
                    <AnalyzerHistory />
                )
            }
        </div>
    );
};

export default Analyzer;

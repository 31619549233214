import { Radio } from 'antd';
import { useMemo } from 'react';

import { Division, IncomeCalculation } from '../../utils/interfaces/halcyon360';

interface CalculatorChoicesRowParams {
    choices: Division[];
    selectOptionalField: (_ancestry: string, _division: Division, _addition: Division, _isChoice: boolean) => void;
    ancestry: string;
    division: Division;
    calculation: IncomeCalculation;
}

const CalculatorChoicesRow = ({
    choices,
    selectOptionalField,
    ancestry,
    division,
    calculation,
}: CalculatorChoicesRowParams) => {
    const def = useMemo<string | undefined>(() => (division.children?.length ? division.children[0].id : undefined), [division]);

    return (
        <Radio.Group
            defaultValue={def}
            options={choices.map((choice) => ({ id: choice.id, label: calculation.fields[choice.id]?.prefix?.title || calculation.fields[choice.id]?.title || choice.id, value: choice.id }))}
            onChange={(e) => {
                selectOptionalField(
                    ancestry,
                    division,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    division.choices.find(({ id }) => (id === e.target.value)),
                    true,
                );
            }}
        />
    );
};

export default CalculatorChoicesRow;

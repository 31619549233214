/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { AppDispatch } from "../../configureStore";
import { addSelectedDivision, addUpDivision, duplicateDivision, getDivCols, removeDivision, summarizeCalculator } from "../../utils/halcyon360.ts";
import {
    AllResponseObject,
    AnalyzerInfo,
    BusinessTaxPayer,
    TaxPayer,
    TaxPayerYear,
    TaxReturns,
} from "../../utils/interfaces/analyzer";
import { AuditLogById, AuditType, Calculation, CurrentAudit, defaultCalculation, Division, Field, Flag, FormHash, IncomeCalculation, IValue, SaveCalculator, Tints, YearFlags } from "../../utils/interfaces/halcyon360";
import execute from "../http/execute";
import { encPost } from "../http/request";
import { Payload } from ".";
import success from "../http/success";
import failure from "../http/failure";

export const RESET_ANALYZER_STATE = 'analyzer/RESET_ANALYZER_STATE';

export const DONE_FIRST_OFF = 'analyzer/DONE_FIRST_OFF';

export const HAS_HALCYON_360 = 'analyzer/HAS_HALCYON_360';
export const SUMMARIZE = 'analyzer/SUMMARIZE';
export const SUMMARIZE_WITH_ADD = 'analyzer/SUMMARIZE_WITH_ADD';

export const FLAG_MODAL_OPEN = 'analyzer/FLAG_MODAL_OPEN';

export const UPDATE_FLAG = 'analyzer/UPDATE_FLAG';

export const SET_CALCULATOR_BORROWER = 'analyzer/SET_CALCULATOR_BORROWER';

export const SET_CALCULATOR_ID = 'analyzer/SET_CALCULATOR_ID';
export const SET_CALCULATION = 'analyzer/SET_CALCULATION';
export const UPDATE_CALCULATION = 'analyzer/UPDATE_CALCULATION';
export const UPDATE_CALCULATION_NAME = 'analyzer/UPDATE_CALCULATION_NAME';
export const UPDATE_CALCULATION_MONTHS = 'analyzer/UPDATE_CALCULATION_MONTHS';

export const YEAR_COUNT = 'analyzer/YEAR_COUNT';
export const CHANGE_YEAR = 'analyzer/CHANGE_YEAR';

export const DUPLICATE_DIVISION = 'analyzer/DUPLICATE_DIVISION';
export const SELECT_OPTIONAL_FIELD = 'analyzer/SELECT_OPTIONAL_FIELD';

export const RESET_PREVIEW = 'analyzer/RESET_PREVIEW';

export const GET_ANALYZER_REQUEST = 'analyzer/GET_ANALYZER_REQUEST';
export const GET_ANALYZER_SUCCESS = 'analyzer/GET_ANALYZER_SUCCESS';
export const GET_ANALYZER_FAILURE = 'analyzer/GET_ANALYZER_FAILURE';

export const GET_CALCULATORS_REQUEST = 'analyzer/GET_CALCULATORS_REQUEST';
export const GET_CALCULATORS_SUCCESS = 'analyzer/GET_CALCULATORS_SUCCESS';
export const GET_CALCULATORS_FAILURE = 'analyzer/GET_CALCULATORS_FAILURE';

export const SAVE_CALCULATORS_REQUEST = 'analyzer/SAVE_CALCULATORS_REQUEST';
export const SAVE_CALCULATORS_SUCCESS = 'analyzer/SAVE_CALCULATORS_SUCCESS';
export const SAVE_CALCULATORS_FAILURE = 'analyzer/SAVE_CALCULATORS_FAILURE';

export const NO_SAVE_CALCULATORS_SUCCESS = 'analyzer/NO_SAVE_CALCULATORS_SUCCESS';

export const GET_VIEW_CALCULATOR_REQUEST = 'analyzer/GET_VIEW_CALCULATOR_REQUEST';
export const GET_VIEW_CALCULATOR_SUCCESS = 'analyzer/GET_VIEW_CALCULATOR_SUCCESS';
export const GET_VIEW_CALCULATOR_FAILURE = 'analyzer/GET_VIEW_CALCULATOR_FAILURE';

export const TOGGLE_ANALYZER_HISTORY = 'analyzer/TOGGLE_ANALYZER_HISTORY';

export interface FlagState {
    open: boolean;
    fieldId?: string;
    year?: string;
    location?: string[];
}

const defaultTaxReturn = {
    returnUploaded: false,
    fileNames: [],
};

const initialState: {
    loanNumber?: string;
    hasAccess: boolean,
    doneFirst: boolean;
    calculation: Calculation;
    divisionTints: Tints;
    noData: boolean;
    calculatorId?: string;
    taxReturns: TaxReturns;
    loanExists: boolean;
    calculators: {
        [type: string]: Calculation;
    };
    changeBased: {
        [type: string]: Calculation;
    };
    formHashes: FormHash,
    taxPayers: {
        [id: string]: {
            info: TaxPayer;
            data: TaxPayerYear[];
            flags: YearFlags;
        };
    };
    businesses: {
        [id: string]: {
            info: BusinessTaxPayer;
            data: TaxPayerYear[];
        };
    };
    types: { [type: string]: string; };
    devInfo?: {
        institutionName: string;
        loanNumber: string;
    };
    currentUpdates: { [iii: string]: { [key: string]: CurrentAudit; } };
    activeBorrower?: string;
    hasChanges: boolean;
    history: {
        open: boolean;
        divisionId?: string;
        fieldType?: 'subtotal' | 'factor';
        fieldIds: string[];
    };
    flagModal: FlagState;
    preview?: Calculation,
} = {
    hasAccess: false,
    doneFirst: false,
    calculation: defaultCalculation,
    calculators: {},
    changeBased: {},
    taxReturns: defaultTaxReturn,
    loanExists: false,
    divisionTints: {},
    noData: false,
    types: {},
    currentUpdates: {},
    hasChanges: false,
    formHashes: {},
    taxPayers: {},
    businesses: {},
    history: {
        open: false,
        fieldIds: [],
    },
    flagModal: {
        open: false,
    },
};

type ActionParams =
    | { type: typeof RESET_ANALYZER_STATE; }
    | { type: typeof DONE_FIRST_OFF; }
    | { type: typeof FLAG_MODAL_OPEN; fieldId?: string; year?: string; location?: string[]; }
    | { type: typeof SUMMARIZE_WITH_ADD; location: string[]; }
    | { type: typeof SUMMARIZE; }
    | { type: typeof SET_CALCULATOR_ID; id: string; }
    | { type: typeof HAS_HALCYON_360; value: boolean; }
    | {
        type: typeof GET_ANALYZER_SUCCESS;
        data: AnalyzerInfo;
    }
    | { type: typeof NO_SAVE_CALCULATORS_SUCCESS; data: Calculation }
    | { type: typeof SAVE_CALCULATORS_SUCCESS; data: { calculator?: Calculation; success: boolean; } }
    | { type: typeof GET_CALCULATORS_SUCCESS; data: { [type: string]: Calculation; }; loanNumber: string; }
    | { type: typeof SET_CALCULATION; calculation: Calculation; }
    | { type: typeof YEAR_COUNT; add: boolean; }
    | {
        type: typeof UPDATE_CALCULATION;
        year: string;
        division: Division;
        location: string[];
        newValue: number;
        removeOverride?: boolean;
        isFactor?: boolean;
    }
    | {
        type: typeof UPDATE_CALCULATION_MONTHS;
        newValue: number;
    }
    | { type: typeof SET_CALCULATOR_BORROWER; id: string; }
    | {
        type: typeof UPDATE_CALCULATION_NAME;
        value: string;
        divisionId: number;
    }
    | {
        type: typeof CHANGE_YEAR;
        value: number;
        index: number;
    }
    | {
        type: typeof TOGGLE_ANALYZER_HISTORY;
        divisionId: string
        fieldType?: 'subtotal' | 'factor'
        fieldIds: string[];
    }
    | {
        type: typeof UPDATE_FLAG;
        auditType: AuditType;
        index: number;
        flag: Flag;
        fieldId: string;
        year: string;
    }
    | {
        type: typeof GET_VIEW_CALCULATOR_SUCCESS;
        data: { calculator: Calculation; };
    }
    | {
        type: typeof DUPLICATE_DIVISION;
        ancestry: string;
        division: Division;
        isRemove: boolean;
    }
    | {
        type: typeof SELECT_OPTIONAL_FIELD;
        ancestry: string;
        division: Division;
        addition: Division;
        isChoice: boolean;
    }
    | {
        type: typeof RESET_PREVIEW;
    };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_VIEW_CALCULATOR_SUCCESS:
            return {
                ...state,
                preview: action.data.calculator,
            };

        case RESET_PREVIEW:
            return {
                ...state,
                preview: undefined,
            };

        case SET_CALCULATOR_ID:
            if (!state.calculators[action.id]) return state;
            const hasChanges = Boolean(state.activeBorrower && state.formHashes[state.activeBorrower] && !state.calculators[action.id].isDefault && state.formHashes[state.activeBorrower] !== state.calculators[action.id].formHashes[state.activeBorrower]);

            return {
                ...state,
                calculatorId: action.id,
                calculation: state.calculators[action.id],
                currentUpdates: {},
                divisionTints: (state.activeBorrower && state.calculators[action.id].data[state.activeBorrower]) ? getDivCols(state.calculators[action.id].data[state.activeBorrower].divisions, state.calculators[action.id].data[state.activeBorrower].fields, true).map : state.divisionTints,
                hasChanges,
                history: {
                    open: false,
                    fieldIds: [],
                },
            };

        case RESET_ANALYZER_STATE:
            return {
                ...initialState,
                hasAccess: state.hasAccess,
            };

        case HAS_HALCYON_360:
            return {
                ...state,
                hasAccess: action.value,
            };

        case UPDATE_FLAG:
            if (!state.activeBorrower) return state;
            const cfu: IncomeCalculation = structuredClone(state.calculation.data[state.activeBorrower]);
            const valueYear = cfu.fields[action.fieldId].values[action.year];
            if (!valueYear) return state;
            const flags: Flag[] = valueYear.flag ?? [];
            flags[action.index] = action.flag;
            valueYear.flag = flags;

            const flagAudit: CurrentAudit = {
                date: new Date(),
                year: action.year,
                iii: state.activeBorrower,
                fieldId: action.fieldId,
                type: action.auditType,
                flagId: action.flag.id,
                value: action.auditType === 'flag-active' ? action.flag.active : action.flag.multiplier,
            };

            cfu.fields[action.fieldId].values[action.year] = valueYear;

            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: cfu,
                    },
                },
                currentUpdates: {
                    ...state.currentUpdates,
                    [state.activeBorrower]: {
                        ...state.currentUpdates[state.activeBorrower],
                        [`${flagAudit.fieldId}-${flagAudit.type}-${flagAudit.year}`]: flagAudit,
                    },
                },
            };

        case FLAG_MODAL_OPEN:
            return {
                ...state,
                flagModal: {
                    open: Boolean(action.fieldId?.length),
                    fieldId: action.fieldId,
                    year: action.year,
                    location: action.location,
                },
            };

        case TOGGLE_ANALYZER_HISTORY:
            return {
                ...state,
                history: {
                    open: !state.history.open,
                    divisionId: action.divisionId,
                    fieldType: action.fieldType,
                    fieldIds: action.fieldIds,
                },
            };

        case SET_CALCULATOR_BORROWER: {
            return {
                ...state,
                activeBorrower: action.id,
            };
        }

        case CHANGE_YEAR:
            if (!state.activeBorrower) return state;
            const usedYears = structuredClone(state.calculation.data[state.activeBorrower || ''].usedYears);
            usedYears[action.index] = String(action.value);

            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: {
                            ...state.calculation.data[state.activeBorrower],
                            usedYears,
                        },
                    },
                },
                currentUpdates: {
                    ...state.currentUpdates,
                    [state.activeBorrower]: {
                        ...state.currentUpdates[state.activeBorrower],
                        [`${action.index}-year`]: {
                            date: new Date(),
                            iii: state.activeBorrower,
                            fieldId: `year.${action.index}`,
                            type: 'year',
                            value: action.value,
                        },
                    },
                },
            };

        case SUMMARIZE: {
            if (!state.activeBorrower) return state;
            const inc = state.calculation.data[state.activeBorrower || ''];

            const newSummary = summarizeCalculator(inc);
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: {
                            ...state.calculation.data[state.activeBorrower],
                            summary: newSummary,
                        },
                    },
                },
            };
        }

        case SUMMARIZE_WITH_ADD: {
            if (!state.activeBorrower) return state;
            const inc = structuredClone(state.calculation.data[state.activeBorrower || '']);
            const ancestors: Division[] = [];
            if (action.location.length > 1) {
                let lastDiv: Division | undefined = inc.divisions.find(({ id }: any) => id === action.location[1]);
                action.location.forEach((locale, idx) => {
                    if (idx > 1 && lastDiv) {
                        lastDiv = lastDiv.children?.find(({ id }) => id === locale);
                    }
                    if (idx && lastDiv) ancestors.push(lastDiv);
                });
            }

            ancestors.pop();
            ancestors.reverse();
            ancestors.forEach((ancestor) => {
                const field: Field = inc.fields[ancestor.id];
                const vals: { [year: string]: number; } = addUpDivision(ancestor, inc.fields, inc.years);
                field.values = Object.keys(vals).reduce<IValue>((a, c) => ({ ...a, [c]: { override: vals[c], value: vals[c] } }), {});
            });

            const newSummary = summarizeCalculator(inc);
            inc.summary = newSummary;
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: inc,
                    },
                },
            };
        }

        case YEAR_COUNT: {
            const { add } = action;
            const copied: Calculation = structuredClone(state.calculation);
            if (!state.activeBorrower) return state;
            const inc: IncomeCalculation = copied.data[state.activeBorrower];
            if (add) {
                const unusedYear = inc.years.find((y) => !inc.usedYears.includes(y));
                if (!unusedYear) return state;
                inc.usedYears.push(unusedYear);
            } else {
                if (inc.usedYears.length === 1) return state;
                inc.usedYears.pop();
            }
            inc.summary.months = inc.usedYears.length * 12;

            const currUpdate: CurrentAudit = {
                date: new Date(),
                iii: state.activeBorrower,
                fieldId: 'yearcount',
                type: 'yearcount',
                value: inc.usedYears.length,
            };
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: inc,
                    },
                },
                currentUpdates: {
                    ...state.currentUpdates,
                    [currUpdate.iii]: {
                        ...state.currentUpdates[currUpdate.iii],
                        [`${currUpdate.fieldId}-${currUpdate.type}`]: currUpdate,
                    },
                },
            };
        }

        case SAVE_CALCULATORS_SUCCESS: {
            if (!action.data.success || !action.data.calculator) return state;
            const newTypes = structuredClone(state.types);
            newTypes[action.data.calculator.type] = action.data.calculator.calculatorId;

            return {
                ...state,
                types: newTypes,
                calculators: {
                    ...state.calculators,
                    [action.data.calculator.calculatorId]: {
                        ...action.data.calculator,
                        auditLogById: action.data.calculator.auditLog.reduce<AuditLogById>((accTung, { fieldId, iii }) => {
                            const toAdd: { [fieldId: string]: boolean; } = accTung[iii] ?? {};
                            toAdd[fieldId] = true;
                            return {
                                ...accTung,
                                [iii]: toAdd,
                            };
                        }, {}),
                    },
                },
                divisionTints: (state.activeBorrower && action?.data?.calculator?.data?.[state.activeBorrower || '']) ? getDivCols(action.data.calculator.data[state.activeBorrower].divisions, action.data.calculator.data[state.activeBorrower].fields, true).map : state.divisionTints,
                currentUpdates: {},
                calculation: action.data.calculator,
            };
        }

        case NO_SAVE_CALCULATORS_SUCCESS: {
            const newTypes = structuredClone(state.types);
            newTypes[action.data.type] = action.data.calculatorId;

            return {
                ...state,
                types: newTypes,
                calculators: {
                    ...state.calculators,
                    [action.data.calculatorId]: {
                        ...action.data,
                        auditLogById: action.data.auditLog.reduce<AuditLogById>((accTung, { fieldId, iii }) => {
                            const toAdd: { [fieldId: string]: boolean; } = accTung[iii] ?? {};
                            toAdd[fieldId] = true;
                            return {
                                ...accTung,
                                [iii]: toAdd,
                            };
                        }, {}),
                    },
                },
                divisionTints: (state.activeBorrower && action?.data?.data?.[state.activeBorrower || '']) ? getDivCols(action.data.data[state.activeBorrower].divisions, action.data.data[state.activeBorrower].fields, true).map : state.divisionTints,
                currentUpdates: {},
                calculation: action.data,
            };
        }

        case DONE_FIRST_OFF: {
            return {
                ...state,
                doneFirst: false,
            };
        }

        case GET_ANALYZER_SUCCESS: {
            if (state.doneFirst) return state;
            const { calculator, data, changedBase } = action.data;
            const newstate: typeof initialState = {
                ...state,
                doneFirst: true,
                loanExists: data.loanExists,
                taxReturns: data.taxReturns ?? defaultTaxReturn,
                noData: data.noData,
                changeBased: Object.values(changedBase).reduce((acc, curr: Calculation) => ({
                    ...acc,
                    [curr.type]: curr,
                }), {}),
                formHashes: data.formHashes,
                businesses: data.businesses.reduce((acc, biz) => ({
                    ...acc,
                    [biz.irsIncomeId]: {
                        info: {
                            id: biz.irsIncomeId,
                            businessName: biz.businessName,
                            forms: biz.forms,
                            irsIncomeId: biz.irsIncomeId,
                            encompassBorrowerId: biz.encompassBorrowerId,
                        },
                        data: Object.keys(biz.data).map((yearStr: string) => ({
                            year: Number(yearStr),
                            data: biz.data[yearStr],
                        })).sort((a, b) => b.year - a.year),
                    },
                }), {}),
                taxPayers: data.incomeInfo.reduce((acc, tp) => ({
                    ...acc,
                    [tp.irsIncomeId]: {
                        info: {
                            id: tp.irsIncomeId,
                            firstName: tp.firstName,
                            lastName: tp.lastName,
                            name: `${tp.firstName} ${tp.lastName}`,
                            forms: tp.forms,
                            irsIncomeId: tp.irsIncomeId,
                            encompassBorrowerId: tp.encompassBorrowerId,
                        },
                        data: Object.keys(tp.data).map((yearStr: string) => ({
                            year: Number(yearStr),
                            data: tp.data[yearStr],
                        })).sort((a, b) => b.year - a.year),
                        flags: tp.flags ? Object.keys(tp.flags).reduce((facc, curr) => ({ ...facc, [curr]: tp?.flags?.[curr].reduce((a, f) => ({ ...a, [f.id]: f }), {}) }), {}) : {},
                    },
                }), state.taxPayers),
            };
            if (data.all) {
                newstate.taxPayers.all = {
                    info: {
                        firstName: 'all',
                        lastName: 'all',
                        id: 'all',
                        name: 'all',
                        irsIncomeId: 'all',
                        forms: [],
                    },
                    data: Object.keys(data.all.data).map<TaxPayerYear>((yearStr: string) => ({
                        year: Number(yearStr),
                        data: (data.all as AllResponseObject).data[yearStr],
                    })).sort((a, b) => b.year - a.year),
                    flags: data.all.flags ? Object.keys(data.all.flags).reduce((facc, curr) => ({ ...facc, [curr]: data.all?.flags?.[curr].reduce((a, f) => ({ ...a, [f.id]: f }), {}) }), {}) : {},
                };
            }

            if (data.gotLoan && data.institutionName) {
                newstate.devInfo = {
                    institutionName: data.institutionName,
                    loanNumber: data.gotLoan,
                };
            }

            if (!newstate.activeBorrower && !data.noData) {
                const useActiveBorrower = data.incomeInfo[0].irsIncomeId;
                newstate.activeBorrower = useActiveBorrower;
                if (newstate.calculation && !newstate.calculation.isDefault && newstate.calculation.formHashes && newstate.calculation.formHashes[useActiveBorrower]) {
                    newstate.hasChanges = data.formHashes[useActiveBorrower] !== newstate.calculation.formHashes[useActiveBorrower];
                }
            }

            newstate.types = Object.keys(calculator).reduce((acc, curr) => ({ ...acc, [curr]: calculator[curr].calculatorId }), {});
            newstate.calculators = Object.values(calculator).reduce((acc, curr: Calculation) => ({
                ...acc,
                [curr.calculatorId]: {
                    ...curr,
                    auditLogById: curr.auditLog.reduce<AuditLogById>((accTung, { fieldId, iii }) => {
                        const toAdd: { [fieldId: string]: boolean; } = accTung[iii] ?? {};
                        toAdd[fieldId] = true;
                        return {
                            ...accTung,
                            [iii]: toAdd,
                        };
                    }, {}),
                },
            }), {});
            newstate.calculation = state.calculation.calculatorId === 'default' ? {
                ...Object.values(calculator)[0],
                auditLogById: Object.values(calculator)[0].auditLog.reduce<AuditLogById>((accTung, { fieldId, iii }) => {
                    const toAdd: { [fieldId: string]: boolean; } = accTung[iii] ?? {};
                    toAdd[fieldId] = true;
                    return {
                        ...accTung,
                        [iii]: toAdd,
                    };
                }, {}),
            } : state.calculation;

            newstate.divisionTints = (newstate.activeBorrower && newstate.calculation.data[newstate.activeBorrower]) ? getDivCols(newstate.calculation.data[newstate.activeBorrower].divisions, newstate.calculation.data[newstate.activeBorrower].fields, true).map : state.divisionTints;

            return newstate;
        }

        case SET_CALCULATION: {
            const hasChanges0 = Boolean(state.activeBorrower && state.formHashes[state.activeBorrower] && state.formHashes[state.activeBorrower] !== action.calculation.formHashes[state.activeBorrower]);

            return {
                ...state,
                hasChanges: hasChanges0,
                calculation: action.calculation,
                divisionTints: (state.activeBorrower && action.calculation.data[state.activeBorrower]) ? getDivCols(action.calculation.data[state.activeBorrower].divisions, action.calculation.data[state.activeBorrower].fields, true).map : state.divisionTints,
            };
        }

        case UPDATE_CALCULATION_MONTHS: {
            if (!state.activeBorrower) return state;
            const copied: IncomeCalculation = structuredClone(state.calculation.data[(state.activeBorrower)]);
            copied.summary.months = action.newValue;
            copied.summary = summarizeCalculator(copied);
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: copied,
                    },
                },
            };
        }

        case UPDATE_CALCULATION: {
            if (!state.activeBorrower) return state;
            const {
                location,
                year,
                division,
                newValue,
            } = action;
            const newUpdate: CurrentAudit = {
                fieldId: action.division.id,
                year: action.year,
                date: new Date(),
                iii: state.activeBorrower,
                type: action.isFactor ? 'factor' : 'override',
                value: newValue,
            };

            const copied: IncomeCalculation = structuredClone(state.calculation.data[state.activeBorrower]);
            if (action.isFactor) {
                if (copied.fields[division.id].subtotal) {
                    // @ts-ignore
                    copied.fields[division.id].subtotal.factor[year] = {
                        // @ts-ignore
                        ...copied.fields[division.id].subtotal.factor[year],
                        override: action.removeOverride ? undefined : newValue,
                    };
                }
            } else {
                copied.fields[division.id].values[year] = {
                    ...copied.fields[division.id].values[year],
                    override: action.removeOverride ? undefined : newValue,
                };
            }

            const ancestors: Division[] = [];
            if (location.length > 1 || action.isFactor) {
                let lastDiv: Division | undefined = copied.divisions.find(({ id }) => id === location[1]);
                location.forEach((locale, idx) => {
                    if (idx > 1 && lastDiv) {
                        lastDiv = lastDiv.children?.find(({ id }) => id === locale);
                    }
                    if (idx && lastDiv) ancestors.push(lastDiv);
                    else if (action.isFactor && lastDiv) ancestors.push(lastDiv);
                });
            }

            if (location.length > 1 && !action.isFactor) ancestors.pop();
            ancestors.reverse();
            ancestors.forEach((ancestor) => {
                const field: Field = copied.fields[ancestor.id];
                if (!field) return;
                const vals: { [year: string]: number; } = addUpDivision(ancestor, copied.fields, copied.years);

                if (field.subtotal && !action.isFactor) {
                    field.subtotal.values = Object.keys(vals).reduce<IValue>((a, c) => ({ ...a, [c]: { override: vals[c], value: vals[c] } }), {});
                }
                if (field.subtotal) {
                    Object.keys(vals).forEach((v) => {
                        const multi: number = ((field.subtotal?.factor?.[v]?.override ?? field.subtotal?.factor?.[v]?.value ?? 100) * (field.subtotal?.isCents ? 100 : 1));
                        vals[v] = Math.ceil(vals[v] * (field.subtotal?.isDivisor ? multi ? (1 / multi) : 100 : (multi / 100)));
                    });
                }
                field.values = Object.keys(vals).reduce<IValue>((a, c) => ({ ...a, [c]: { override: vals[c], value: vals[c] } }), {});
            });

            copied.summary = summarizeCalculator(copied);

            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: copied,
                    },
                },
                currentUpdates: {
                    ...state.currentUpdates,
                    [newUpdate.iii]: {
                        ...state.currentUpdates[newUpdate.iii],
                        [`${newUpdate.fieldId}-${newUpdate.year}`]: newUpdate,
                    },
                },
            };
        }

        case UPDATE_CALCULATION_NAME: {
            if (!state.activeBorrower) return state;
            const { divisionId, value: nameValue } = action;

            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: {
                            ...state.calculation.data[state.activeBorrower],
                            fields: {
                                ...state.calculation.data[state.activeBorrower].fields,
                                [divisionId]: {
                                    ...state.calculation.data[state.activeBorrower].fields[divisionId],
                                    prefix: {
                                        ...state.calculation.data[state.activeBorrower].fields[divisionId].prefix,
                                        input: {
                                            ...(state.calculation.data[state.activeBorrower].fields[divisionId].prefix?.input || {}),
                                            value: nameValue,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                currentUpdates: {
                    ...state.currentUpdates,
                    [state.activeBorrower]: {
                        [`${divisionId}-name`]: {
                            date: new Date(),
                            fieldId: `${divisionId}-name`,
                            iii: 'root',
                            type: 'name',
                            value: nameValue,
                        },
                    },
                },
            };
        }

        case DUPLICATE_DIVISION:
            if (!state.activeBorrower) return state;

            if (action.isRemove) {
                const removed = removeDivision(action.ancestry, action.division, state.calculation.data[state.activeBorrower]);
                return {
                    ...state,
                    calculation: {
                        ...state.calculation,
                        data: {
                            ...state.calculation.data,
                            [state.activeBorrower]: removed,
                        },
                    },
                    divisionTints: getDivCols(removed.divisions, removed.fields, true, true).map,
                    currentUpdates: {
                        ...state.currentUpdates,
                        [state.activeBorrower]: {
                            ...state.currentUpdates[state.activeBorrower],
                            ...(action.division.id && {
                                [`duplicate-${action.division.id}`]: {
                                    date: new Date(),
                                    fieldId: action.division.id,
                                    iii: state.activeBorrower,
                                    type: 'field-remove',
                                    value: 'removed',
                                },
                            }),
                        },
                    },
                };
            }

            const duped = duplicateDivision(action.ancestry, action.division, state.calculation.data[state.activeBorrower]);
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: duped.incCalc,
                    },
                },
                divisionTints: getDivCols(duped.incCalc.divisions, duped.incCalc.fields, true, true).map,
                currentUpdates: {
                    ...state.currentUpdates,
                    [state.activeBorrower]: {
                        ...state.currentUpdates[state.activeBorrower],
                        ...(duped.newId && {
                            [`duplicate-${action.division.id}`]: {
                                date: new Date(),
                                fieldId: duped.newId,
                                iii: state.activeBorrower,
                                type: 'field-add',
                                value: 'added',
                            },
                        }),
                    },
                },
            };

        case SELECT_OPTIONAL_FIELD:
            if (!state.activeBorrower) return state;
            const added = addSelectedDivision(action.ancestry, action.division, action.addition, state.calculation.data[state.activeBorrower], state.taxPayers[state.activeBorrower], action.isChoice);
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    data: {
                        ...state.calculation.data,
                        [state.activeBorrower]: added.incCalc,
                    },
                },
                divisionTints: getDivCols(added.incCalc.divisions, added.incCalc.fields, true, true).map,
                currentUpdates: {
                    ...state.currentUpdates,
                    [state.activeBorrower]: {
                        ...state.currentUpdates[state.activeBorrower],
                        ...(added.newId && {
                            [`duplicate-${action.division.id}`]: {
                                date: new Date(),
                                fieldId: added.newId,
                                iii: state.activeBorrower,
                                type: 'field-add',
                                value: 'added',
                            },
                        }),
                    },
                },
            };

        default:
            return state;
    }
}

export const setCalculation = (calculation: Calculation) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_CALCULATION,
        calculation,
    });
};

export const getCalculatorData = (payload: Payload<{
    partnerAccessToken: string,
    originId: string
}>) => (dispatch: AppDispatch) => {
    dispatch({ type: GET_ANALYZER_REQUEST });

    execute(encPost, '/Analyzer', payload.input).then((res: { data: { analyzerInfo: AnalyzerInfo; } }) => {
        dispatch({ type: GET_ANALYZER_SUCCESS, data: res.data.analyzerInfo });
    }).catch((err) => {
        console.error(err);
        dispatch({ type: GET_ANALYZER_FAILURE });
    });
};

export const saveCalculation = (payload: Payload<SaveCalculator>) => (dispatch: AppDispatch) => {
    execute(encPost, '/Calculator', payload.input)
        .then(success(dispatch, SAVE_CALCULATORS_SUCCESS, payload))
        .catch(failure(dispatch, SAVE_CALCULATORS_FAILURE, payload));

    dispatch({
        type: SAVE_CALCULATORS_REQUEST,
        payload,
    });
};

export const noSaveCalculation = (calc: Calculation) => (dispatch: AppDispatch) => {
    dispatch({
        type: NO_SAVE_CALCULATORS_SUCCESS,
        data: calc,
    });
};

export const getViewCalculator = (payload: Payload<{ partnerAccessToken: string; originId: string; loanNumber: string; calculatorId: string; }>) => (dispatch: AppDispatch) => {
    execute(encPost, `/Analyzer`, payload.input, {})
        .then(success(dispatch, GET_VIEW_CALCULATOR_SUCCESS, payload))
        .catch(failure(dispatch, GET_VIEW_CALCULATOR_FAILURE, payload));

    dispatch({
        type: GET_VIEW_CALCULATOR_REQUEST,
        payload,
    });
};

export default reducer;

/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import _ from 'lodash';
import { v4 } from 'uuid';

import {
    Calculation,
    Division,
    Field,
    Fields,
    Flag,
    IncomeCalculation,
    IValue,
    Removables,
    Summary,
    YearFlag,
    YearFlags,
} from '../interfaces/halcyon360';
import { TaxPayer, TaxPayerYear } from '../interfaces/analyzer';

export function isHigherVersion(current: string, newVersion: string) {
    const currentParts = current.split('.').map(Number);
    const newParts = newVersion.split('.').map(Number);

    for (let i = 0; i < 3; i++) {
        if (newParts[i] > currentParts[i]) {
            return true;
        }
        if (newParts[i] < currentParts[i]) {
            return false;
        }
    }
    return false;
}

export const addUpDivision = (
    division: Division,
    fields: Fields,
    years: string[],
): { [year: string]: number; } => {
    const vals: { [year: string]: number; } = years.reduce((a, c) => ({ ...a, [c]: 0 }), {});
    (division?.children || []).forEach((div: Division) => {
        Object.keys(vals).forEach((v) => {
            const flag = (fields[div.id]?.values?.[v]?.flag ?? []).find(({ active: a }) => a);

            const val = (fields[div.id]?.values?.[v]?.override ?? fields[div.id]?.values?.[v]?.value ?? 0) * (flag?.multiplier ?? 1);
            switch (fields[div.id].operation) {
                case 'subtract':
                    vals[v] -= val;
                    break;
                case 'multi':
                    vals[v] *= val;
                    break;
                case 'none':
                    break;
                case 'add':
                default:
                    vals[v] += val;
                    break;
            }
        });
    });

    // if (fields[division.id]?.subtotal) {
    //     Object.keys(vals).forEach((v) => {
    //         const multi: number = ((fields[division.id].subtotal?.factor?.[v]?.override ?? fields[division.id].subtotal?.factor?.[v]?.value ?? 100) * (fields[division.id].subtotal?.isCents ? 100 : 1));
    //         vals[v] = Math.ceil(vals[v] * (multi / 100));
    //     });
    // }

    return vals;
};

const getDivCols = (divisions: Division[], fields: Fields, last = true, start = true): { last: boolean; map: { [id: string]: boolean; } } => {
    let map: { [id: string]: boolean; } = {};
    divisions.forEach((div) => {
        if (fields[div.id].prefix && !start) {
            map[`${div.id}-p`] = !last;
            last = !last;
            if (div.choices) {
                map[`${div.id}-c`] = !last;
                last = !last;
            }
            if (fields[div.id].prefix?.input) {
                map[`${div.id}-i`] = !last;
                last = !last;
            }
        }
        if (div.children) {
            const children = getDivCols(div.children, fields, last, false);
            map = {
                ...map,
                ...children.map,
            };
            last = children.last;
        }
        if (div.options) {
            map[`${div.id}-o`] = !last;
            last = !last;
        }

        map[div.id] = !last;
        last = !last;

        if (fields[div.id].subtotal && fields[div.id].subtotal?.title) {
            if (fields[div.id].subtotal?.multiplyTitle) {
                map[`${div.id}-m`] = !last;
                last = !last;
            }
            map[`${div.id}-s`] = !last;
            last = !last;
        }
    });
    return { map, last };
};

const summarizeCalculator = (calc: IncomeCalculation): Summary => {
    const { months } = calc.summary;
    const { usedYears, divisions, fields } = calc;
    const totals: { [year: string]: number; } = usedYears.reduce((a, c) => ({
        ...a,
        [c]: Math.ceil(divisions.reduce((acc, curr) => (acc + (fields[curr.id]?.values?.[c]?.override ?? (fields[curr.id]?.values?.[c]?.value || 0))), 0)),
    }), {});
    const total = Math.ceil(_.sum(Object.values(totals)));
    const income = Math.ceil(months === 0 ? total : total / months);

    return {
        ...calc.summary,
        income,
        total,
        totals,
    };
};

interface IDMap { [old: string]: string; }
export const generateDivisionIds = (division: Division, map: IDMap, fields: Fields, nullout: boolean = false, indent?: number) => {
    if (division.children) {
        division.children.forEach((div) => {
            generateDivisionIds(div, map, fields, nullout);
        });
    }

    if (division.options) {
        division.options.forEach((div) => {
            generateDivisionIds(div, map, fields, nullout);
        });
    }

    if (division.choices) {
        division.choices.forEach((div) => {
            generateDivisionIds(div, map, fields, nullout);
        });
    }

    const newId = v4();

    map[division.id] = newId;
    const newField = structuredClone({
        ...fields[division.id],
        id: newId,
    });

    if (nullout) {
        newField.values = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (newField.prefix?.input?.value) newField.prefix.input.value = undefined;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (newField.subtotal && !newField.subtotal.fixedFactor && newField.subtotal.factorValue) newField.subtotal.factorValue = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (newField.subtotal && !newField.subtotal.fixedFactor && newField.subtotal.factor) newField.subtotal.factor = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (newField.subtotal && newField.subtotal.values) newField.subtotal.values = {};
        newField.flagIds = [];
    }
    if (indent) newField.indent = indent;
    fields[newId] = newField;
};

const replaceDivisionIds = (division: Division, map: IDMap, index: number): Division => {
    division.oId = division.oId || division.id;
    division.id = map[division.id];

    if (division.children) {
        division.children.forEach((div) => {
            replaceDivisionIds(div, map, index);
        });
    }

    if (division.options) {
        division.options.forEach((div) => {
            replaceDivisionIds(div, map, index);
        });
    }

    if (division.choices) {
        division.choices.forEach((div) => {
            replaceDivisionIds(div, map, index);
        });
    }

    division.index = index;
    return division;
};

const removeFields = (division: Division, incomeCalculation: IncomeCalculation) => {
    if (division.children) division.children.forEach((d) => removeFields(d, incomeCalculation));
    if (division.options) division.options.forEach((d) => removeFields(d, incomeCalculation));
    if (division.choices) division.choices.forEach((d) => removeFields(d, incomeCalculation));
    delete incomeCalculation.fields[division.id];
};

export const removeDivision = (ancestry: string, division: Division, incomeCalculation: IncomeCalculation): IncomeCalculation => {
    const incCalc: IncomeCalculation = structuredClone(incomeCalculation);
    const isStart = ancestry === 'start';
    if (isStart) {
        const baseIdx = incCalc.divisions.findIndex(({ id }) => division.id === id);
        const path = `divisions[${baseIdx}]`;
        const removeFromDivision: Division = _.get(incCalc, path);
        removeFields(removeFromDivision, incCalc);

        incCalc.divisions = incCalc.divisions.filter(({ id }) => division.id !== id);
    } else {
        const location: string[] = ancestry.split('|');
        const ancestors: Division[] = [];
        let path = 'divisions';
        if (location.length > 0) {
            let { divisions } = incCalc;
            location.forEach((id) => {
                if (id === 'start') return;
                const idx = divisions.findIndex(({ id: childId }) => childId === id);
                path += `[${idx}].children`;
                ancestors.push(divisions[idx]);
                divisions = divisions?.[idx]?.children || [];
            });
            path = path.substring(0, path.length - 9);
        }

        const removeFromDivision: Division = _.get(incCalc, path);
        removeFromDivision.children = removeFromDivision.children?.filter(({ id }) => id !== division.id) ?? [];

        removeFields(division, incCalc);

        ancestors.reverse();
        ancestors.forEach((ancestor) => {
            const field: Field = incCalc.fields[ancestor.id];
            const vals: { [year: string]: number; } = addUpDivision(ancestor, incCalc.fields, incCalc.years);
            field.values = Object.keys(vals).reduce<IValue>((a, c) => ({ ...a, [c]: { override: vals[c], value: vals[c] } }), {});
        });
    }
    incCalc.summary = summarizeCalculator(incCalc);

    return incCalc;
};

export const duplicateDivision = (ancestry: string, division: Division, incomeCalculation: IncomeCalculation): { incCalc: IncomeCalculation, newId?: string; } => {
    const incCalc: IncomeCalculation = structuredClone(incomeCalculation);
    if (division.index === undefined) division.index = 0;
    const map: IDMap = {};
    generateDivisionIds(division, map, incCalc.fields, true);
    const isStart = ancestry === 'start';

    const div = replaceDivisionIds(structuredClone(division), map, division.index + 1);
    const location: string[] = ancestry.split('|');
    let path = 'divisions';
    if (location.length > 0) {
        let { divisions } = incCalc;
        location.forEach((id) => {
            if (id === 'start') return;
            const idx = divisions.findIndex(({ id: childId }) => childId === id);
            path += `[${idx}].children`;
            divisions = divisions?.[idx]?.children || [];
        });
        path = path.substring(0, path.length - 9);
    }

    if (isStart) {
        const baseIdx = incCalc.divisions.findIndex(({ id }) => division.id === id);
        path = `divisions[${baseIdx}]`;
        const addDivision: Division = _.get(incCalc, path);
        if (!addDivision.children) return { incCalc };
        const spliceIdx = baseIdx + 1;
        incCalc.divisions.splice(spliceIdx, 0, div);
        incCalc.fields[div.id].isSupplement = true;
        return { incCalc, newId: div.id };
    }

    const addDivision: Division = _.get(incCalc, path);
    if (!addDivision.children) return { incCalc };
    const spliceIdx = (addDivision.children.findIndex(({ id }) => id === division.id)) + 1;
    addDivision.children.splice(spliceIdx, 0, div);
    incCalc.fields[div.id].isSupplement = true;
    return { incCalc, newId: div.id };
};

const getMultis = (incCalc: IncomeCalculation, divisions: Division[], yearTaxInfo: TaxPayerYear) => {
    const adds: { div: Division, spliceIdx: number; oId: string; }[] = [];
    let lastMultifield: string | undefined;
    divisions.forEach((division: Division, spliceIdx: number) => {
        if (division.children) {
            getMultis(incCalc, division.children, yearTaxInfo);
        }
        if (incCalc.fields[division.id].multifield) {
            if (lastMultifield === incCalc.fields[division.id].multifield) {
                adds.pop();
            } else {
                lastMultifield = incCalc.fields[division.id].multifield;
                const obj = _.get(yearTaxInfo, `data.${incCalc.fields[division.id].multifield}`) as (any[] | undefined);
                if (obj) {
                    division.index = 0;
                    if (obj.length > 0) {
                        obj.forEach((_f, i) => {
                            if (!i) return;
                            const map: IDMap = {};
                            generateDivisionIds(division, map, incCalc.fields);
                            adds.push({ oId: division.oId || division.id, div: replaceDivisionIds(structuredClone(division), map, i), spliceIdx: spliceIdx + i });
                        });
                    }
                }
            }
        }
    });

    const addsOids: string[] = [];
    const addsByOid: { [oid: string]: { div: Division, spliceIdx: number; oId: string; }[]; } = adds.reduce((acc, curr) => {
        if (!addsOids.includes(curr.oId)) addsOids.push(curr.oId);
        const toAdd = acc[curr.oId] ?? [];
        toAdd.push(curr);
        return {
            ...acc,
            [curr.oId]: toAdd,
        };
    }, {} as { [oid: string]: { div: Division, spliceIdx: number; oId: string; }[]; });

    addsOids.reverse().forEach((addId) => {
        addsByOid[addId].forEach((adder) => {
            divisions.splice(adder.spliceIdx || 0, 0, adder.div);
        });
    });
    return divisions;
};

const getValueFromPath = (path: string, year: TaxPayerYear, index?: number, flags?: Flag[]): { value: string | undefined; flag?: Flag[] } => {
    const forceZeroIfNan: boolean = index != null && index > 0 && path.includes('[i]');
    if (path.includes('[i]') && index != null) {
        path = path.replace('[i]', `[${index}]`);
    } else if (path.includes('[i]')) {
        path = path.replace('[i]', '[0]');
    }
    const hasFlags: Flag[] = [];
    if (flags?.length) {
        hasFlags.push(...flags.filter((fl) => fl.location.some((l) => path.startsWith(l))));
    }
    const value: string | undefined = _.get(year, `data.${path}`);
    if (!value && forceZeroIfNan) return { value: '0', flag: hasFlags.length ? hasFlags : undefined };
    return { value, flag: hasFlags.length ? hasFlags : undefined };
};

const populate2 = (
    division: Division,
    fields: Fields,
    year: TaxPayerYear,
    removeables: Removables,
    removeable: boolean,
    flags: YearFlag,
    flagged: Flag[] = [],
): {
    popped: number | undefined;
    flag?: Flag[]
} => {
    const useYear = String(year.year);
    const field = fields[division.id];
    if (removeable || field.removable) {
        removeables[division.id] = {
            ...(removeables[division.id] || {}),
            [useYear]: false,
        };
    }

    const newFlags: Flag[] = structuredClone(flagged);
    if (field.flagIds) {
        field.flagIds.forEach(({ id: fl, active }) => {
            if (flags[fl] && active) {
                newFlags.push(flags[fl]);
            }
        });
    }

    if (field.prefix?.input) {
        let valueFound: string | undefined;
        if (field.prefix?.input.valuePath) {
            const { value } = getValueFromPath(field.prefix?.input.valuePath, year, division.index);
            if (value && value !== '0') valueFound = value;
        }
        if (field.prefix?.input.businessName && !valueFound) {
            const { value } = getValueFromPath(field.prefix?.input.businessName, year, division.index);
            if (value && value !== '0') valueFound = value;
        }
        if (valueFound) {
            field.prefix.input.value = valueFound;
        }
    }

    if (division.children) {
        let summed = 0;
        let found = false;
        division.children.forEach((div) => {
            const { popped: poppedChild, flag: childFlags } = populate2(div, fields, year, removeables, Boolean(removeable || field.removable), flags, newFlags);
            const flag: Flag | undefined = childFlags?.find(({ active }) => active);
            const multiplier = flag?.multiplier ?? 1;

            if (!poppedChild && poppedChild !== 0) return;

            found = true;
            switch (fields[div.id].operation) {
                case 'none':
                    break;
                case 'multi':
                    summed *= (poppedChild * multiplier);
                    break;
                case 'subtract':
                    summed -= (poppedChild * multiplier);
                    break;
                case 'add':
                default:
                    summed += (poppedChild * multiplier);
                    break;
            }
        });

        field.values[useYear] = {
            ...(field.values[useYear] || {}),
            value: found ? Math.ceil(summed) : undefined,
        };

        if (field.subtotal && found) {
            field.subtotal.values = {
                ...field.subtotal.values,
                [useYear]: {
                    ...field.subtotal.values[useYear],
                    value: summed ? Math.ceil(structuredClone(summed)) : summed,
                },
            };
            if (field?.subtotal?.factorValue?.[year.year]?.value) {
                const subValue = field.subtotal.factorValue[year.year].value;
                field.subtotal.factor = {
                    ...field.subtotal.factor,
                    [useYear]: {
                        ...field.subtotal.factor[useYear],
                        value: subValue,
                    },
                };
            } else if (field.subtotal.factorPath) {
                const { value: subValue } = getValueFromPath(field.subtotal.factorPath, year, division.index);
                if (subValue && !Number.isNaN(Number(subValue.replace(/%/g, '')))) {
                    field.subtotal.factor = {
                        ...field.subtotal.factor,
                        [useYear]: {
                            ...field.subtotal.factor[useYear],
                            value: Number(subValue.replace(/%/g, '')),
                        },
                    };

                    summed *= ((Number(subValue.replace(/%/g, '')) || 100) / 100);
                    field.values[useYear].value = Math.ceil(summed);
                }
            }
        } else if (field?.subtotal?.factorValue?.[year.year]?.value) {
            const subValue = field.subtotal.factorValue[year.year].value;
            field.subtotal.factor = {
                ...field.subtotal.factor,
                [useYear]: {
                    ...field.subtotal.factor[useYear],
                    value: subValue,
                },
            };
        } else if (field.subtotal && field.subtotal.defaultFactor) {
            field.subtotal.factor = {
                ...field.subtotal.factor,
                [useYear]: {
                    ...field.subtotal.factor[useYear],
                    value: Math.ceil(Number(field.subtotal.defaultFactor)),
                },
            };

            summed *= field.subtotal.isDivisor ? (1 / Number(field.subtotal.defaultFactor || 1)) : (Number(field.subtotal.defaultFactor || 100) / 100);
            field.values[useYear].value = Math.ceil(summed);
        }

        if (!found && (removeable || field.removable)) removeables[division.id][useYear] = true;
        return {
            popped: found
                ? field.subtotal?.isCents
                    ? Math.ceil(summed) * 100
                    : Math.ceil(summed)
                : undefined,
        };
    }

    if (field?.path) {
        if (Array.isArray(field.path)) {
            let summed = 0;
            let found = false;
            const flagSum: Flag[] = [];
            const flagSet: Set<string> = new Set<string>();
            field.path.forEach((path) => {
                const { value, flag } = getValueFromPath(path.path, year, division.index, newFlags);

                if (!value) return;
                const valNum = Number(value);
                if (Number.isNaN(valNum)) return;
                if (flag) {
                    flag.forEach((fl) => {
                        if (!flagSet.has(fl.id)) {
                            flagSet.add(fl.id);
                            flagSum.push(fl);
                        }
                    });
                }

                found = true;

                switch (path.operation) {
                    case 'none':
                        break;
                    case 'multi':
                        summed *= valNum;
                        break;
                    case 'subtract':
                        summed -= valNum;
                        break;
                    case 'add':
                    default:
                        summed += valNum;
                        break;
                }
            });
            field.values[useYear] = {
                ...(field.values[useYear] || {}),
                value: found ? Math.ceil(summed) : undefined,
                flag: flagSum.length ? flagSum : undefined,
            };

            if (!found && (removeable || field.removable)) removeables[division.id][useYear] = true;
            return { popped: found ? Math.ceil(summed) : undefined, flag: flagSum.length ? flagSum : undefined };
        }
        const { value, flag } = getValueFromPath(field.path, year, division.index, newFlags);
        if (!value) {
            if (removeable || field.removable) removeables[division.id][useYear] = true;
            return { popped: undefined };
        }
        const valNum = Number(value);
        if (Number.isNaN(valNum)) {
            if (removeable || field.removable) removeables[division.id][useYear] = true;
            return { popped: undefined };
        }

        field.values[useYear] = {
            ...(field.values[useYear] || {}),
            value: valNum ? Math.ceil(valNum) : valNum,
            flag,
        };
        return { popped: valNum, flag };
    }

    if (removeable || field.removable) removeables[division.id][useYear] = true;
    return { popped: undefined };
};

export const incomeCalculate = (incCalc: IncomeCalculation, years: TaxPayerYear[], flags: YearFlags, isDefault: boolean) => {
    const newCalc: IncomeCalculation = structuredClone(incCalc);
    newCalc.divisions = getMultis(newCalc, incCalc.divisions, years[0]);
    newCalc.years = years.map(({ year }) => String(year));
    if (!newCalc.usedYears) newCalc.usedYears = newCalc.years.length > 1 ? [newCalc.years[0], newCalc.years[1]] : [newCalc.years[0]];

    const removeables: Removables = {};
    newCalc.years.forEach((yearNum) => {
        const year = years.find(({ year: y }) => y === Number(yearNum));
        if (!year) return;
        newCalc.divisions.forEach((division, _divIndex) => {
            populate2(division, newCalc.fields, year, removeables, incCalc.fields?.[division.id]?.removable || false, flags[year.year]);
        });
    });
    Object.keys(removeables).forEach((key) => { if (Object.values(removeables[key]).every(Boolean)) newCalc.fields[key].hide = true; });
    newCalc.summary.months = isDefault ? newCalc.usedYears.length * 12 : newCalc.summary.months;
    newCalc.summary = summarizeCalculator(newCalc);

    return newCalc;
};

const populateCalculator = (calculator: Calculation, incomeInfo: { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; flags: YearFlags; } }): Calculation => {
    const copied: Calculation = structuredClone(calculator);
    Object.keys(incomeInfo).forEach((iii: string) => {
        if (!iii) return;
        const years = incomeInfo[iii].data;
        if (!copied.data[iii]) copied.data[iii] = structuredClone(copied.data.any);

        copied.data[iii] = incomeCalculate(copied.data[iii], years, incomeInfo[iii].flags, calculator.isDefault || false);
        copied.data[iii].forms = incomeInfo[iii].info.forms;
    });

    return copied;
};

const flatDiv = (d: Division): string[] => (d.children ? [d.id, ...d.children.flatMap(flatDiv)] : [d.id]);

export const addSelectedDivision = (
    ancestry: string,
    division: Division,
    addition: Division,
    incomeCalculation: IncomeCalculation,
    years: {
        info: TaxPayer;
        data: TaxPayerYear[];
        flags: YearFlags;
    },
    isChoice: boolean,
): { incCalc: IncomeCalculation, newId?: string; } => {
    const incCalc: IncomeCalculation = structuredClone(incomeCalculation);

    const location: string[] = ancestry.split('|');
    location.push(division.id);
    let path = 'divisions';
    if (location.length > 0) {
        let { divisions } = incCalc;
        location.forEach((id) => {
            if (id === 'start') return;
            const idx = divisions.findIndex(({ id: childId }) => childId === id);
            path += `[${idx}].children`;
            divisions = divisions?.[idx]?.children || [];
        });
        path = path.substring(0, path.length - 9);
    }

    const addDivision: Division = _.get(incCalc, path);
    if (isChoice) {
        addDivision.children = [addition];
        years.data.forEach((year) => {
            populate2(addition, incCalc.fields, year, {}, false, years.flags[year.year]);
        });
        incCalc.fields[addition.id].isSupplement = true;
        const ancestors: Division[] = [];
        if (location.length > 1) {
            let lastDiv: Division | undefined = incCalc.divisions.find(({ id }) => id === location[1]);
            location.forEach((locale, idx) => {
                if (idx > 1 && lastDiv) {
                    lastDiv = lastDiv.children?.find(({ id }) => id === locale);
                }
                if (idx && lastDiv) ancestors.push(lastDiv);
            });
        }

        ancestors.push(division);
        ancestors.pop();
        ancestors.reverse();
        ancestors.forEach((ancestor) => {
            const field: Field = incCalc.fields[ancestor.id];
            const vals: { [year: string]: number; } = addUpDivision(ancestor, incCalc.fields, incCalc.years);
            field.values = Object.keys(vals).reduce<IValue>((a, c) => ({ ...a, [c]: { override: vals[c], value: vals[c] } }), {});
        });
        incCalc.summary = summarizeCalculator(incCalc);
        return { incCalc, newId: addition.id };
    }

    const map: IDMap = {};
    const lastIndexArr = division.children?.filter(({ id }) => incCalc.fields[id].title === incCalc.fields[addition.id].title) || [];
    const lastIndex = lastIndexArr.length ? lastIndexArr[lastIndexArr.length - 1].index || -1 : -1;

    generateDivisionIds(addition, map, incCalc.fields, true, ((incCalc.fields?.[division.id]?.indent || 0) + 2));
    const div = replaceDivisionIds(structuredClone(addition), map, lastIndex + 1);

    if (!addDivision.children) return { incCalc };
    incCalc.fields[div.id].isSupplement = true;
    incCalc.fields[div.id].isOptional = true;
    addDivision.children.push(div);
    years.data.forEach((year) => {
        populate2(div, incCalc.fields, year, {}, false, years.flags[year.year]);
    });
    return { incCalc, newId: div.id };
};

const mergeUpdatedCalculator = (target: Calculation, source: Calculation) => {
    Object.keys(target.data).forEach((iii) => {
        Object.keys(target.data[iii].fields).forEach((fieldId) => {
            if (source.data[iii].fields[fieldId]) {
                Object.keys(source.data[iii].fields[fieldId].values).forEach((yr) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (source.data[iii].fields[fieldId].values[yr]?.override) target.data[iii].fields[fieldId].values[yr].override = source.data[iii].fields[fieldId].values[yr].override;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (source.data[iii].fields[fieldId].subtotal?.values?.[yr]?.override && target.data[iii].fields[fieldId]?.subtotal?.values?.[yr]) target.data[iii].fields[fieldId].subtotal.values[yr].override = source.data[iii].fields[fieldId].subtotal.values[yr].override;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (source.data[iii].fields[fieldId].subtotal?.factorValue?.[yr]?.override && target.data[iii].fields[fieldId].subtotal?.factorValue?.[yr]) target.data[iii].fields[fieldId].subtotal.factorValue[yr].override = source.data[iii].fields[fieldId].subtotal.factorValue[yr].override;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (source.data[iii].fields[fieldId].prefix?.input?.override && target.data[iii].fields[fieldId].prefix?.input) target.data[iii].fields[fieldId].prefix.input.override = source.data[iii].fields[fieldId].prefix.input.override;
                });
            }
        });
    });
};

export {
    populateCalculator,
    summarizeCalculator,
    flatDiv,
    getDivCols,
    mergeUpdatedCalculator,
};

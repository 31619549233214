import {
    Button,
    Col,
    Row,
    Typography,
} from 'antd';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../configureStore';
import {
    CombinedTaxPayer,
    CombinedTaxPayerForms,
    FormsRequested,
} from '../../utils/interfaces/analyzer';
import TaxPayerFormsUsed from './TaxPayerFormsUsed';
import UploadReturnModal from './UploadReturnModal';

interface FormsUsedParams {
}

const FormsUsed = ({
}: FormsUsedParams) => {
    const taxPayers = useSelector<RootState, CombinedTaxPayer[]>(({ analyzer }) => [...Object.values(analyzer.taxPayers).map((tp) => tp.info).filter(({ id }) => id !== 'all'), ...Object.values(analyzer.businesses).map((tp) => tp.info)]);
    const loanExists = useSelector<RootState, boolean>(({ analyzer: { loanExists: le } }) => le);

    const [returnOpen, setReturnOpen] = useState<boolean>(false);

    const forms = useMemo<CombinedTaxPayerForms[]>(() => taxPayers.map<CombinedTaxPayerForms>((tp) => ({
        ...tp,
        usedForms: tp.forms.reduce((acc: { [form: string]: string[]; }, curr: FormsRequested) => {
            const toAdd: string[] = acc[curr.formDescription] ?? [];
            toAdd.push(curr.taxPeriod);
            return {
                ...acc,
                [curr.formDescription]: toAdd,
            };
        }, {}),
    })), [taxPayers]);

    return (
        <>
            <UploadReturnModal
                open={returnOpen}
                setOpen={setReturnOpen}
            />
            <Row justify="space-between" gutter={8} align="middle" className="mb-4">
                <Typography className="analyzer_card_title mr-1">
                    Forms Available
                </Typography>
                {loanExists && (
                    <Row justify="end" align="middle" gutter={8}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => { setReturnOpen(!returnOpen); }}
                            >
                                Upload Tax Return
                            </Button>
                        </Col>
                    </Row>
                )}
            </Row>
            <Row gutter={[16, 16]}>
                {
                    forms.map((tp) => (
                        <Col
                            key={`forms_used_${tp.irsIncomeId}-${tp.id}`}
                            xs={24}
                            sm={12}
                            md={8}
                        >
                            <TaxPayerFormsUsed
                                key={`forms-${tp.id}`}
                                taxPayer={tp}
                                isBusiness={Object.hasOwn(tp, 'businessName')}
                            />
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default FormsUsed;

/* eslint-disable import/no-cycle */
/* eslint-disable default-param-last */
import { Payload } from ".";
import { RiaTransaction } from "../../utils/encompass/riaTransaction";
import { APIOrigin, APIOriginFromBackend, OriginError, TransactionOrigin } from "../../utils/interfaces/encompass";
import { encPost } from "../http/request";
import { AppDispatch } from "../../configureStore";
import HostConnection, { IHostConnection } from "../../utils/em-host";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import sandbox from "../../sandbox";
import { ERROR_MESSAGE } from "./status";
import { EFolderTransaction } from "../../utils/encompass/eFolderTransaction";
import { CancelTransaction } from "../../utils/encompass/cancelTransaction";
import { ResubmitTransaction } from "../../utils/encompass/resubmitTransaction";
import { RequestTransaction } from "../../utils/encompass/requestTransaction";
import { AddedTransaction } from "../../utils/encompass/addedTransaction";
import { UpdateTransaction } from "../../utils/encompass/updateTransaction";
import { ResendAuthorizationTransaction } from "../../utils/encompass/resendAuthorizationTransaction";
import { CheckAuthTransaction } from "../../utils/encompass/checkAuthTransaction";
import { UploadReturnTransaction } from "../../utils/encompass/uploadReturn";

const initialState: {
    host?: IHostConnection
    origin?: APIOrigin;
    transactionOrigin?: TransactionOrigin;
    loanNumber?: string;
    primaryBorrowerId?: string;
    unauthorized: boolean;
    actionOpen: boolean;
} = {
    unauthorized: false,
    actionOpen: false,
};

export const GET_HOST_REQUEST = 'encompass/GET_HOST_REQUEST';
export const GET_HOST_SUCCESS = 'encompass/GET_HOST_SUCCESS';
export const GET_HOST_FAILURE = 'encompass/GET_HOST_FAILURE';

export const GET_TXN_ORIGIN_REQUEST = 'encompass/GET_TXN_ORIGIN_REQUEST';
export const GET_TXN_ORIGIN_SUCCESS = 'encompass/GET_TXN_ORIGIN_SUCCESS';
export const GET_TXN_ORIGIN_FAILURE = 'encompass/GET_TXN_ORIGIN_FAILURE';

export const GET_ORIGIN_REQUEST = 'encompass/GET_ORIGIN_REQUEST';
export const GET_ORIGIN_SUCCESS = 'encompass/GET_ORIGIN_SUCCESS';
export const GET_ORIGIN_FAILURE = 'encompass/GET_ORIGIN_FAILURE';

export const CREATE_TRANSACTION_REQUEST = 'encompass/CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'encompass/CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'encompass/CREATE_TRANSACTION_FAILURE';

export const CREATE_RIA_TRANSACTION_REQUEST = 'encompass/CREATE_RIA_TRANSACTION_REQUEST';
export const CREATE_RIA_TRANSACTION_SUCCESS = 'encompass/CREATE_RIA_TRANSACTION_SUCCESS';
export const CREATE_RIA_TRANSACTION_FAILURE = 'encompass/CREATE_RIA_TRANSACTION_FAILURE';

export const SET_UNAUTHORIZED = 'encompass/SET_UNAUTHORIZED';

export const SET_ACTION_OPEN = 'encompass/SET_ACTION_OPEN';

type ActionParams =
    | { type: typeof GET_HOST_SUCCESS; payload: Payload; data: IHostConnection }
    | { type: typeof GET_HOST_FAILURE; payload: Payload; data: any }
    | { type: typeof GET_TXN_ORIGIN_SUCCESS; payload: Payload; data: TransactionOrigin }
    | { type: typeof GET_ORIGIN_SUCCESS; payload: Payload; data: APIOriginFromBackend }
    | { type: typeof SET_ACTION_OPEN; actionOpen: boolean; }
    | { type: typeof SET_UNAUTHORIZED; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_HOST_SUCCESS:
            sandbox.set('host', action.data);
            return {
                ...state,
                host: action.data,
            };
        case GET_HOST_FAILURE:
            sandbox.set('host', undefined);
            return {
                ...state,
            };
        case GET_TXN_ORIGIN_SUCCESS:
            return {
                ...state,
                transactionOrigin: action.data,
            };
        case GET_ORIGIN_SUCCESS:
            return {
                ...state,
                loanNumber: action.data.loanNumber,
                primaryBorrowerId: action.data.primaryBorrowerId,
                origin: {
                    ...action.data,
                    origin: JSON.parse(action.data.origin),
                },
            };
        case SET_UNAUTHORIZED:
            return {
                ...state,
                unauthorized: true,
            };
        case SET_ACTION_OPEN:
            return {
                ...state,
                actionOpen: action.actionOpen,
            };
        default:
            return state;
    }
}

export const getHost = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_HOST_REQUEST,
        payload,
    });

    HostConnection()
        .then((res) => dispatch({
            type: GET_HOST_SUCCESS,
            data: res,
        }))
        .catch((err) => dispatch({
            type: GET_HOST_FAILURE,
            payload: err,
        }));
};

export const getTransactionOrigin = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.getTransactionOrigin()
        .then((res) => {
            dispatch({
                type: GET_TXN_ORIGIN_SUCCESS,
                data: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_TXN_ORIGIN_FAILURE,
                payload: err,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: "Something went wrong getting your session.",
            });
        });

    dispatch({
        type: GET_TXN_ORIGIN_REQUEST,
        payload,
    });
};

export const getTransactionOriginPromise = (payload?: Payload<any>) => async (dispatch: AppDispatch): Promise<
    {
        id: string;
        partnerAccessToken: string;
    }
> => {
    const host: IHostConnection = sandbox.get('host');
    dispatch({
        type: GET_TXN_ORIGIN_REQUEST,
        payload,
    });

    try {
        const res = await host.getTransactionOrigin();
        dispatch({
            type: GET_TXN_ORIGIN_SUCCESS,
            data: res,
        });
        return res;
    } catch (err) {
        dispatch({
            type: GET_TXN_ORIGIN_FAILURE,
            payload: err,
        });

        dispatch({
            type: ERROR_MESSAGE,
            message: "Something went wrong getting your session.",
        });
        throw err;
    }
};

export const refreshOrigin = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.refreshOrigin()
        .then((res) => {
            dispatch({
                type: GET_TXN_ORIGIN_SUCCESS,
                data: res,
            });
            if (payload?.onSuccess) {
                payload.onSuccess(res);
            }
        })
        .catch((err) => {
            dispatch({
                type: GET_TXN_ORIGIN_FAILURE,
                payload: err,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: "Something went wrong refreshing your session.",
            });
        });

    dispatch({
        type: GET_TXN_ORIGIN_REQUEST,
        payload,
    });
};

export const closeTransaction = () => (_dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.close().then().catch();
};

export const sendEncompassRequest = (payload: Payload<UpdateTransaction | UploadReturnTransaction | AddedTransaction | EFolderTransaction | CancelTransaction | ResubmitTransaction | RequestTransaction | ResendAuthorizationTransaction | CheckAuthTransaction>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    dispatch({ type: CREATE_TRANSACTION_REQUEST });
    if (!payload.input) {
        dispatch({ type: CREATE_TRANSACTION_FAILURE });
        return;
    }
    host.createTransaction(payload.input.toTransaction()).then(() => {
        dispatch({
            type: CREATE_TRANSACTION_SUCCESS,
            payload,
        });
        if (payload?.onSuccess) payload.onSuccess({});
    }).catch(() => {
        dispatch({ type: CREATE_TRANSACTION_FAILURE });
        if (payload?.onError) payload.onError({});
    });
};

export const sendEncompassRiaRequest = (payload: Payload<RiaTransaction>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    dispatch({
        type: CREATE_RIA_TRANSACTION_REQUEST,
    });
    if (!payload.input) {
        dispatch({ type: CREATE_RIA_TRANSACTION_FAILURE });
        return;
    }
    host.createTransaction(payload.input.toTransaction()).then(() => {
        dispatch({
            type: CREATE_RIA_TRANSACTION_SUCCESS,
            payload,
        });
        if (payload?.onSuccess) payload.onSuccess({});
    }).catch(() => {
        dispatch({ type: CREATE_RIA_TRANSACTION_SUCCESS });
        dispatch({ type: ERROR_MESSAGE, message: 'Something went wrong sending the invitation!' });
        if (payload?.onError) payload.onError({});
    });
};

export const getOrigin = (payload: Payload<any>) => (dispatch: AppDispatch) => {
    execute(encPost, '/Origin', payload.input)
        .then((res) => {
            try {
                const origin = JSON.parse(res.data.origin);
                if (origin.errors) {
                    const firstError: OriginError = origin.errors[0];
                    if (firstError.code === 'EPC-1400') {
                        const host: IHostConnection = sandbox.get('host');
                        host.refreshOrigin().then((refRes) => {
                            dispatch({
                                type: GET_TXN_ORIGIN_SUCCESS,
                                data: refRes,
                            });
                            const triedRefresh = Boolean(sandbox.get('triedRefresh'));
                            sandbox.set('triedRefresh', true);

                            if (!triedRefresh) {
                                dispatch(getTransactionOriginPromise({ input: { getOrigin: true } }))
                                    .then((successfulRes) => {
                                        dispatch(getOrigin({
                                            input: {
                                                partnerAccessToken: successfulRes.partnerAccessToken,
                                                originId: successfulRes.id,
                                                version: 2,
                                            },
                                        }));
                                    })
                                    .catch(() => {
                                        failure(dispatch, GET_ORIGIN_FAILURE, 'Something went wrong!')(payload);
                                    });
                            }
                            sandbox.set('triedRefresh', true);
                        }).catch(() => {
                            failure(dispatch, GET_ORIGIN_FAILURE, 'Your session has expired, please close and reopen the application.')(payload);
                        });
                    } else {
                        throw new Error('Something went wrong getting your data');
                    }
                } else {
                    success(dispatch, GET_ORIGIN_SUCCESS, payload)(res);
                }
            } catch (err) {
                console.error('BONG', err);
                failure(dispatch, GET_ORIGIN_FAILURE, payload, 'Failure originating service.')(res);
            }
        })
        .catch((err) => {
            if (err.response?.data?.status === 401) {
                dispatch({
                    type: SET_UNAUTHORIZED,
                });
            }
            failure(dispatch, GET_ORIGIN_FAILURE, payload, err?.response?.data?.title || 'Failure originating service.')(payload);
        });

    dispatch({
        type: GET_ORIGIN_REQUEST,
        payload,
    });
};

export default reducer;

import { useMemo, useState } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import {
    Col, Popover, Row, Tooltip,
} from 'antd';
import Icon from '../atoms/Icon';

export interface MoreMenuOptions {
    name: string
    onClick?: any
    disabled?: boolean
    toolTipText?: string
    visible?: boolean
}

interface MoreMenuProps {
    options: MoreMenuOptions[];
    placement?: TooltipPlacement;
}

export default function MoreMenu(props: MoreMenuProps) {
    const [visible, setVisible] = useState(false);

    const menuList = useMemo(
        () => (
            <Row style={{ width: '180px', color: '#505F79', fontSize: '12px' }}>
                {props.options.map((o, index) => (
                    <Tooltip key={o.name} title={o.toolTipText} placement={props.placement || 'bottomLeft'} trigger="hover" zIndex={50000}>
                        <Col
                            onClick={() => {
                                if (!o.disabled) {
                                    o.onClick();
                                    setVisible(false);
                                }
                            }}
                            key={index}
                            style={{
                                paddingTop: index ? '10px' : 0,
                                cursor: o.disabled ? 'not-allowed' : 'pointer',
                                display: o.visible === false ? 'none' : 'block',
                            }}
                            span={24}
                        >
                            {o.name}
                        </Col>
                    </Tooltip>
                ))}
            </Row>
        ),
        [props.options],
    );

    return (
        <Popover
            placement="bottomLeft"
            arrow={{ pointAtCenter: true }}
            content={menuList}
            trigger="click"
            open={visible}
            onOpenChange={(vis) => setVisible(vis)}
        >
            <span style={{ cursor: 'pointer', width: '50px' }}>
                <Icon name="MoreOutlined" />
            </span>
        </Popover>
    );
}

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { IHostConnection } from "../../utils/em-host";
import { AppDispatch, RootState } from "../../configureStore";
import sandbox from "../../sandbox";
import Icon from "./Icon";
import { IEncompassResource, fileNameTypes } from "../../utils/interfaces/encompass";
import { ERROR_MESSAGE } from "../../redux/reducer/status";

interface EncompassFileSelectorParams {
    isReorder?: boolean;
    fileType?: number;
    value?: IEncompassResource[];
    onChange?: (_?: IEncompassResource[] | undefined, _remove?: boolean) => void;
    disabled?: boolean;
    danger?: boolean;
    allow?: number;
    center?: boolean;
}

const EncompassFileSelector = ({
    fileType,
    isReorder,
    value,
    onChange,
    disabled = false,
    danger = false,
    allow = 1,
    center = false,
}: EncompassFileSelectorParams) => {
    const host: IHostConnection = sandbox.get('host');
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const fileNameBorrower = useSelector<RootState, string | undefined>(({ borrower: { activeBorrower, borrowers } }) => (
        (!isReorder && activeBorrower)
            ? (borrowers[activeBorrower] as any)?.[fileNameTypes[fileType || 0]]
            : undefined
    ));

    const dispatch = useDispatch<AppDispatch>();

    const onClick = async () => {
        if (host) {
            const userSelectedResources: IEncompassResource[] = await host.performAction<any>('getAvailableResources');
            if (allow === 1 && userSelectedResources.length !== 1) {
                dispatch({
                    type: ERROR_MESSAGE,
                    message: "Please include 1 and only 1 file.",
                });
            } else if (userSelectedResources.length > allow) {
                dispatch({
                    type: ERROR_MESSAGE,
                    message: `Please include a maximum of ${allow} files.`,
                });
            } else if (userSelectedResources.length >= 1) {
                const isValid = userSelectedResources.every((usr) => ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(usr.mimeType));
                if (!isValid) {
                    dispatch({
                        type: ERROR_MESSAGE,
                        message: "File must be of type PDF, PNG, JPG, or JPEG",
                    });
                } else {
                    onChange && onChange(userSelectedResources);
                }
            } else {
                dispatch({
                    type: ERROR_MESSAGE,
                    message: "Please include a file.",
                });
            }
        }
    };

    return (
        <div style={center ? undefined : { display: 'flex', alignItems: 'flex-start' }} className={center ? 'flex-center-center col' : undefined}>
            <Button
                onClick={onClick}
                icon={<Icon name="UploadOutlined" />}
                disabled={disabled}
                style={{ borderColor: danger ? '#F05379' : undefined }}
            >
                Click to Upload
            </Button>
            {
                (fileNameBorrower && !(isEdit && value?.length))
                    ? <p className="mt-1 mb-0 ml-2"><Icon name="PaperClipOutlined" size={12} />{fileNameBorrower}</p>
                    : (
                        <div>
                            {(value || []).map(({ name }, idx) => (
                                <p key={`filesel-${name}-${idx}`} className="mt-1 mb-0 ml-2">
                                    {(!fileNameBorrower && !isEdit) && (
                                        <Icon
                                            onClick={() => {
                                                if (onChange) {
                                                    const newUsr: IEncompassResource[] = JSON.parse(JSON.stringify(value));
                                                    newUsr.splice(idx, 1);
                                                    onChange(newUsr, true);
                                                }
                                            }}
                                            className="mr-1"
                                            name="CloseOutlined"
                                            size={14}
                                        />
                                    )}
                                    {name && <Icon name="PaperClipOutlined" size={12} />}
                                    {name}
                                </p>
                            ))}
                        </div>
                    )

            }
        </div>
    );
};

export default EncompassFileSelector;

import { useMemo, useState } from 'react';
import {
    Division, Field, IncomeCalculation, Tints,
} from '../../../utils/interfaces/halcyon360';
import CalculatorChoicesRow from '../../molecules/CalculatorChoicesRow';
import CalculatorPrefix from '../../molecules/CalculatorPrefix';
import CalculatorOptionsRow from '../../molecules/CalculatorOptionsRow';
import CalculatorRow from './CalculatorRow';

interface CalculatorDivisionParams {
    division: Division;
    calculation: IncomeCalculation;
    ancestry: string;
    setHistoryOpen: (l: string[], divisionId: string, fieldType?: 'subtotal' | 'factor') => void;
    duplicate: (_ancestry: string, _division: Division, _isRemove: boolean) => void;
    selectOptionalField: (_ancestry: string, _division: Division, _addition: Division, _isChoice: boolean) => void;
    tints: Tints;
    nextId: string;
}

const CalculatorDivision = ({
    division,
    calculation,
    ancestry,
    setHistoryOpen,
    duplicate,
    selectOptionalField,
    tints,
    nextId,
}: CalculatorDivisionParams) => {
    const field: Field = calculation.fields[division.id];
    const [closed, setClosed] = useState<boolean>(ancestry === 'start');
    const indent = useMemo(() => (((field?.isOptional ? -3 : -1) + ancestry.split('|').length) - (division.children ? 0 : 1) + (field?.indent || 0)), [field, ancestry, division.children]);

    return !field.hide ? (
        <div className={ancestry === 'start' ? 'mt-4' : closed ? 'botbor' : undefined}>
            {
                field?.prefix && (
                    <CalculatorPrefix
                        division={division}
                        field={field}
                        closed={closed}
                        setClosed={setClosed}
                        nextId={nextId}
                        ancestry={ancestry}
                        duplicate={duplicate}
                        indent={indent}
                        tints={tints}
                        selectOptionalField={selectOptionalField}
                        calculation={calculation}
                    />
                )
            }
            <div style={{ display: closed ? 'none' : undefined }}>
                {(!field.prefix && division?.choices) && (
                    <CalculatorChoicesRow
                        choices={division.choices}
                        ancestry={ancestry}
                        calculation={calculation}
                        division={division}
                        selectOptionalField={selectOptionalField}
                    />
                )}
                {(/* eslint-disable no-use-before-define */division?.children) && mapDivisions((division.children || []), calculation, `${ancestry}|${division.id}`, setHistoryOpen, duplicate, selectOptionalField, tints)}
                {division?.options && (
                    <CalculatorOptionsRow
                        ancestry={ancestry}
                        calculation={calculation}
                        division={division}
                        options={division.options}
                        indent={ancestry === 'start' ? 1 : indent === 0 ? 1 : indent}
                        tinted={tints[`${division.id}-o`]}
                        selectOptionalField={selectOptionalField}
                    />
                )}
                {field?.subtotal && (
                    <>
                        {field?.subtotal?.title && (
                            <CalculatorRow
                                division={division}
                                location={`${ancestry}|${division.id}`}
                                setHistoryOpen={setHistoryOpen}
                                className={tints[`${division.id}-s`] ? 'lightMediumGray' : undefined}
                                indent={ancestry === 'start' ? 1 : indent === 0 ? 1 : indent}
                                isSubtotal
                            />
                        )}
                        <CalculatorRow
                            division={division}
                            location={`${ancestry}|${division.id}`}
                            setHistoryOpen={setHistoryOpen}
                            className={tints[`${division.id}-m`] ? 'lightMediumGray' : undefined}
                            indent={ancestry === 'start' ? 1 : indent === 0 ? 1 : indent}
                            isFactor
                        />
                    </>
                )}
                {field?.title && (
                    <CalculatorRow
                        division={division}
                        location={`${ancestry}|${division.id}`}
                        setHistoryOpen={setHistoryOpen}
                        indent={ancestry === 'start' ? 1 : indent === 0 ? 1 : indent}
                        className={tints[division.id] ? 'lightMediumGray' : undefined}
                    />
                )}
            </div>
        </div>
    ) : <div style={{ display: 'none' }} />;
};

export const mapDivisions = (
    divisions: Division[],
    calculation: IncomeCalculation,
    ancestry: string,
    setHistoryOpen: (l: string[], divisionId: string, fieldType?: 'subtotal' | 'factor') => void,
    duplicate: (_ancestry: string, _division: Division, _isRemove: boolean) => void,
    selectOptionalField: (_ancestry: string, _division: Division, _addition: Division, _isChoice: boolean) => void,
    tints: Tints,
) => (
    <div key={ancestry}>
        {
            divisions.map((val, idx) => (
                <CalculatorDivision
                    key={`${ancestry}|${val.id}`}
                    division={val}
                    ancestry={ancestry}
                    calculation={calculation}
                    setHistoryOpen={setHistoryOpen}
                    nextId={divisions?.[idx + 1]?.oId || divisions?.[idx + 1]?.id}
                    duplicate={duplicate}
                    selectOptionalField={selectOptionalField}
                    tints={tints}
                />
            ))
        }
    </div>
);

export default CalculatorDivision;

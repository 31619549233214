import { useState } from 'react';
import {
    Row,
    Button,
    Modal,
    Typography,
    Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CalculatorSelector from '../../molecules/CalculatorSelector';
import SaveCalculatorModal from '../../molecules/SaveCalculatorModal';
import { AppDispatch, RootState } from '../../../configureStore';
import { TOGGLE_ANALYZER_HISTORY } from '../../../redux/reducer/analyzer';
import FlagModal from '../../molecules/FlagModal';

interface HeaderParams {
    setCalculator: (_: string) => void;
    loanNumber: string;
    noData: boolean;
}

const Header = ({
    setCalculator,
    loanNumber,
    noData,
}: HeaderParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const version = useSelector<RootState, number | undefined>(({ analyzer: { calculation } }) => calculation.version);
    const updates = useSelector<RootState, boolean>(({ analyzer: { currentUpdates } }) => Object.keys(currentUpdates).length > 0);
    const isLocked = useSelector<RootState, boolean>(({ analyzer: { calculation } }) => calculation.isLocked || false);

    const [open, setOpen] = useState<boolean>(false);
    const setHistoryOpen = () => {
        dispatch({ type: TOGGLE_ANALYZER_HISTORY, location: undefined, fieldIds: [] });
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Row align="middle" justify="start">
                    <Typography.Title level={4} className="mr-4 mb-0">
                        Loan Number -
                        {' '}
                        {loanNumber}
                        {' '}
                    </Typography.Title>
                    {(version !== -1) && <Tag>{version ? `Version ${version}` : 'Original'}</Tag>}
                </Row>
                <Row align="middle" justify="end">
                    {!noData && (
                        <CalculatorSelector
                            loanNumber={loanNumber}
                        />
                    )}
                    <Button
                        title="Save File"
                        type="primary"
                        onClick={() => {
                            setOpen(true);
                        }}
                        disabled={noData || !updates || isLocked}
                    >
                        Save File
                    </Button>
                    <Button
                        className="ml-1"
                        title="History"
                        onClick={() => {
                            setHistoryOpen();
                        }}
                        disabled={noData}
                    >
                        History
                    </Button>
                </Row>
            </Row>

            <Modal
                open={open}
                destroyOnClose
                onCancel={() => {
                    setOpen(false);
                }}
                footer={null}
            >
                <SaveCalculatorModal
                    setOpen={setOpen}
                    loanNumber={loanNumber || ''}
                    changeCalculator={setCalculator}
                />
            </Modal>
            <FlagModal />
        </>
    );
};

export default Header;
